// Conversion Process:
//    1. Convert excel file to csv (make sure the headers are the following - zipcode, city, yesBranchCode)
//    2. Go to https://www.convertcsv.com/csv-to-json.htm
//    3. Use the 'Load Saved Form" option near the bottom of the page and select 'yesBranchCodes-conversionForm.json'
//    4. Load csv file under 'Step 1'
//    5. Expand step 4 and click on the 'Covert CSV to JSON via Template' button

const yesBranchCodesList = {
  "110001": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110002": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110003": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110004": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110005": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110006": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110007": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110008": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110009": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110010": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110011": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110012": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110013": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110014": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110015": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110016": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110017": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110018": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110019": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110020": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110021": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110022": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110023": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110024": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110025": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110026": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110027": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110028": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110029": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110030": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110031": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110032": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110033": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110034": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110035": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110036": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110037": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110038": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110039": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110040": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110041": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110042": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110043": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110044": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110045": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110046": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110047": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110048": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110049": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110050": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110051": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110052": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110053": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110054": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110055": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110056": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110057": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110058": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110059": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110060": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110061": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110062": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110063": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110064": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110065": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110066": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110067": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110068": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110069": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110070": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110071": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110072": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110073": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110074": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110075": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110076": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110077": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110078": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110080": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110081": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110082": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110083": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110084": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110085": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110086": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110087": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110088": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110089": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110090": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110091": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110092": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110093": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110094": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110095": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110096": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110097": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110098": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110099": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "110100": {
    "city": "NEW DELHI",
    "yesBranchCode": "597"
  },
  "121001": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121002": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121003": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121004": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121005": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121006": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121007": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121008": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121009": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121010": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121011": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121012": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121013": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121014": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121015": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121101": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121102": {
    "city": "PALWAL",
    "yesBranchCode": "722"
  },
  "121103": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121105": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121106": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "121107": {
    "city": "FARIDABAD",
    "yesBranchCode": "531"
  },
  "122001": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122002": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122003": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122004": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122005": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122006": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122007": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122008": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122009": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122010": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122011": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122012": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122015": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122016": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122017": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122018": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122050": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122051": {
    "city": "MANESAR",
    "yesBranchCode": "26"
  },
  "122052": {
    "city": "MANESAR",
    "yesBranchCode": "26"
  },
  "122098": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122101": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122102": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122103": {
    "city": "MEWAT",
    "yesBranchCode": "364"
  },
  "122104": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122107": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122108": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122413": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122414": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122502": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122503": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122504": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122505": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "122506": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "123035": {
    "city": "REWARI",
    "yesBranchCode": "1061"
  },
  "123101": {
    "city": "REWARI",
    "yesBranchCode": "1061"
  },
  "123102": {
    "city": "REWARI",
    "yesBranchCode": "1061"
  },
  "123103": {
    "city": "REWARI",
    "yesBranchCode": "1061"
  },
  "123106": {
    "city": "REWARI",
    "yesBranchCode": "1061"
  },
  "123110": {
    "city": "REWARI",
    "yesBranchCode": "1061"
  },
  "123301": {
    "city": "REWARI",
    "yesBranchCode": "1061"
  },
  "123302": {
    "city": "REWARI",
    "yesBranchCode": "1061"
  },
  "123303": {
    "city": "REWARI",
    "yesBranchCode": "1061"
  },
  "123401": {
    "city": "REWARI",
    "yesBranchCode": "1061"
  },
  "123411": {
    "city": "REWARI",
    "yesBranchCode": "1061"
  },
  "123412": {
    "city": "REWARI",
    "yesBranchCode": "1061"
  },
  "123413": {
    "city": "GURGAON",
    "yesBranchCode": "1025"
  },
  "123501": {
    "city": "BAWAL",
    "yesBranchCode": "141"
  },
  "124001": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124010": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124021": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124022": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124102": {
    "city": "JHAJJAR",
    "yesBranchCode": "501"
  },
  "124103": {
    "city": "JHAJJAR",
    "yesBranchCode": "501"
  },
  "124104": {
    "city": "JHAJJAR",
    "yesBranchCode": "501"
  },
  "124105": {
    "city": "BADLI",
    "yesBranchCode": "383"
  },
  "124106": {
    "city": "JHAJJAR",
    "yesBranchCode": "501"
  },
  "124107": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124108": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124109": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124111": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124112": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124113": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124141": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124142": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124146": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124201": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124202": {
    "city": "JHAJJAR",
    "yesBranchCode": "501"
  },
  "124303": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124401": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124404": {
    "city": "JHAJJAR",
    "yesBranchCode": "501"
  },
  "124406": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124411": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124412": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124501": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124504": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124505": {
    "city": "JHAJJAR",
    "yesBranchCode": "501"
  },
  "124506": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124507": {
    "city": "BAHADURGARH",
    "yesBranchCode": "575"
  },
  "124508": {
    "city": "JHAJJAR",
    "yesBranchCode": "501"
  },
  "124513": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "124514": {
    "city": "ROHTAK",
    "yesBranchCode": "634"
  },
  "125004": {
    "city": "FATEHABAD",
    "yesBranchCode": "987"
  },
  "125047": {
    "city": "FATEHABAD",
    "yesBranchCode": "987"
  },
  "125048": {
    "city": "FATEHABAD",
    "yesBranchCode": "241"
  },
  "125050": {
    "city": "FATEHABAD",
    "yesBranchCode": "241"
  },
  "125051": {
    "city": "FATEHABAD",
    "yesBranchCode": "241"
  },
  "125053": {
    "city": "FATEHABAD",
    "yesBranchCode": "241"
  },
  "125106": {
    "city": "FATEHABAD",
    "yesBranchCode": "173"
  },
  "125111": {
    "city": "FATEHABAD",
    "yesBranchCode": "241"
  },
  "125112": {
    "city": "FATEHABAD",
    "yesBranchCode": "173"
  },
  "125120": {
    "city": "FATEHABAD",
    "yesBranchCode": "173"
  },
  "125133": {
    "city": "FATEHABAD",
    "yesBranchCode": "173"
  },
  "126101": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "126110": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "126112": {
    "city": "SAFIDON",
    "yesBranchCode": "375"
  },
  "126113": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "126114": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "126125": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "126152": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "131001": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131021": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131022": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131023": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131024": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131027": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131028": {
    "city": "KUNDLI",
    "yesBranchCode": "130"
  },
  "131029": {
    "city": "RAI",
    "yesBranchCode": "59"
  },
  "131030": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131039": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131101": {
    "city": "GANAUR",
    "yesBranchCode": "157"
  },
  "131102": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131103": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131301": {
    "city": "GOHANA",
    "yesBranchCode": "159"
  },
  "131302": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131304": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131305": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131306": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131402": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131403": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131408": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "131409": {
    "city": "SONIPAT",
    "yesBranchCode": "219"
  },
  "132001": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132022": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132023": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132024": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132036": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132037": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132039": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132040": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132041": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132046": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132054": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132101": {
    "city": "PANIPAT",
    "yesBranchCode": "498"
  },
  "132102": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132103": {
    "city": "PANIPAT",
    "yesBranchCode": "498"
  },
  "132104": {
    "city": "PANIPAT",
    "yesBranchCode": "498"
  },
  "132105": {
    "city": "PANIPAT",
    "yesBranchCode": "498"
  },
  "132106": {
    "city": "PANIPAT",
    "yesBranchCode": "498"
  },
  "132107": {
    "city": "PANIPAT",
    "yesBranchCode": "498"
  },
  "132108": {
    "city": "PANIPAT",
    "yesBranchCode": "498"
  },
  "132113": {
    "city": "PANIPAT",
    "yesBranchCode": "498"
  },
  "132114": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132115": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132116": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132117": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132122": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "132140": {
    "city": "PANIPAT",
    "yesBranchCode": "498"
  },
  "132145": {
    "city": "PANIPAT",
    "yesBranchCode": "498"
  },
  "132157": {
    "city": "KARNAL",
    "yesBranchCode": "168"
  },
  "133001": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133004": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133005": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133006": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133008": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133010": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133101": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133102": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133103": {
    "city": "YAMUNA NAGAR",
    "yesBranchCode": "1031"
  },
  "133104": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133201": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133202": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133203": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133204": {
    "city": "YAMUNA NAGAR",
    "yesBranchCode": "1031"
  },
  "133205": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133206": {
    "city": "YAMUNA NAGAR",
    "yesBranchCode": "1031"
  },
  "133207": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133301": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "133302": {
    "city": "KALKA",
    "yesBranchCode": "222"
  },
  "134003": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "134005": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "134007": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "134008": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "134101": {
    "city": "PANCHKULA",
    "yesBranchCode": "369"
  },
  "134102": {
    "city": "PANCHKULA",
    "yesBranchCode": "369"
  },
  "134103": {
    "city": "PANCHKULA",
    "yesBranchCode": "369"
  },
  "134104": {
    "city": "PINJORE",
    "yesBranchCode": "369"
  },
  "134105": {
    "city": "PANCHKULA",
    "yesBranchCode": "369"
  },
  "134107": {
    "city": "PANCHKULA",
    "yesBranchCode": "369"
  },
  "134108": {
    "city": "PANCHKULA",
    "yesBranchCode": "369"
  },
  "134109": {
    "city": "PANCHKULA",
    "yesBranchCode": "369"
  },
  "134112": {
    "city": "PANCHKULA",
    "yesBranchCode": "369"
  },
  "134113": {
    "city": "PANCHKULA",
    "yesBranchCode": "369"
  },
  "134114": {
    "city": "PANCHKULA",
    "yesBranchCode": "369"
  },
  "134116": {
    "city": "PANCHKULA",
    "yesBranchCode": "369"
  },
  "134117": {
    "city": "PANCHKULA",
    "yesBranchCode": "369"
  },
  "134201": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "134202": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "134203": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "134204": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "134205": {
    "city": "PANCHKULA",
    "yesBranchCode": "369"
  },
  "135001": {
    "city": "YAMUNA NAGAR",
    "yesBranchCode": "1031"
  },
  "135002": {
    "city": "YAMUNA NAGAR",
    "yesBranchCode": "1031"
  },
  "135003": {
    "city": "YAMUNA NAGAR",
    "yesBranchCode": "1031"
  },
  "135004": {
    "city": "AMBALA",
    "yesBranchCode": "120"
  },
  "135021": {
    "city": "YAMUNA NAGAR",
    "yesBranchCode": "1031"
  },
  "135101": {
    "city": "YAMUNA NAGAR",
    "yesBranchCode": "1031"
  },
  "135102": {
    "city": "YAMUNA NAGAR",
    "yesBranchCode": "1031"
  },
  "135103": {
    "city": "YAMUNA NAGAR",
    "yesBranchCode": "1031"
  },
  "135106": {
    "city": "YAMUNA NAGAR",
    "yesBranchCode": "1031"
  },
  "135133": {
    "city": "YAMUNA NAGAR",
    "yesBranchCode": "1031"
  },
  "136020": {
    "city": "KAITHAL",
    "yesBranchCode": "132"
  },
  "136021": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136026": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136027": {
    "city": "KAITHAL",
    "yesBranchCode": "132"
  },
  "136030": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136033": {
    "city": "KAITHAL",
    "yesBranchCode": "132"
  },
  "136034": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136035": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136038": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136042": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136043": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136044": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136117": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136118": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136119": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136128": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136129": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136130": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136131": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136132": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136134": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136135": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136136": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "136156": {
    "city": "KURUKSHETRA",
    "yesBranchCode": "132"
  },
  "140001": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140101": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "140102": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "140103": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140108": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140109": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "140110": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "140111": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140112": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140113": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140114": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140115": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "140116": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140117": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140118": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140119": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140123": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140124": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140125": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140126": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140127": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140128": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140133": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140201": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "140301": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140306": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "140307": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "140308": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "140401": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "140402": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "140405": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "140406": {
    "city": "FATEHGARH SAHIB",
    "yesBranchCode": "891"
  },
  "140407": {
    "city": "FATEHGARH SAHIB",
    "yesBranchCode": "891"
  },
  "140408": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "140412": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "140413": {
    "city": "ROPAR",
    "yesBranchCode": "146"
  },
  "140417": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "140501": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "140506": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "140507": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "140601": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "140602": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "140603": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "140604": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "140701": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "140702": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "140802": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "140901": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "141001": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141002": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141003": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141004": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141005": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141006": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141007": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141008": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141010": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141012": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141013": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141014": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141015": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141016": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141017": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141101": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141102": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141103": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141104": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141105": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141106": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141107": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141108": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141109": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141110": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141112": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141113": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141114": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141115": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141116": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141117": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141118": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141119": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141120": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141121": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141122": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141123": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141125": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141126": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141127": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141201": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141202": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141203": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141204": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141205": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141206": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141401": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141411": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "141412": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141413": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141414": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141415": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141416": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141417": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141418": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141419": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141421": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141422": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "141801": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "142001": {
    "city": "MOGA",
    "yesBranchCode": "472"
  },
  "142002": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142003": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142011": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142021": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142022": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142023": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142024": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142025": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142026": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142027": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142028": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142029": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142030": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142031": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142032": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142033": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142034": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142035": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142036": {
    "city": "LUDHIANA",
    "yesBranchCode": "1007"
  },
  "142037": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142038": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142039": {
    "city": "MOGA",
    "yesBranchCode": "472"
  },
  "142040": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142042": {
    "city": "FEROZEPUR",
    "yesBranchCode": "780"
  },
  "142043": {
    "city": "FEROZEPUR",
    "yesBranchCode": "472"
  },
  "142045": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142046": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142048": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142049": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142053": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142054": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142055": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142056": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142057": {
    "city": "FARIDKOT",
    "yesBranchCode": "472"
  },
  "142058": {
    "city": "FEROZEPUR",
    "yesBranchCode": "780"
  },
  "143001": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143002": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143003": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143005": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143006": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143008": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143009": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143022": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143101": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143102": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143103": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143105": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143107": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143108": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143109": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143111": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143112": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143113": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143114": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143115": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143116": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143119": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143149": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143201": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143202": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143203": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143204": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143205": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143411": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143501": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143502": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143504": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143505": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143506": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143507": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143511": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143512": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143513": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143514": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143515": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143516": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143517": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143518": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143519": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143520": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143521": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143525": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143526": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143527": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143528": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143529": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143530": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143531": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143532": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143533": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143534": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143601": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143602": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143603": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "143604": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143605": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "143606": {
    "city": "AMRITSAR",
    "yesBranchCode": "844"
  },
  "144001": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144002": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144003": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144004": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144005": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144006": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144007": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144008": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144009": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144010": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144011": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144020": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144021": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144022": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144023": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144024": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144025": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144026": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144027": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144028": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144029": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144030": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144031": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144032": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144033": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144034": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144035": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144036": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144037": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144039": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144040": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144041": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144042": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144043": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144044": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144101": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144102": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144103": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144104": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144105": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144106": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144201": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144202": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144204": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144205": {
    "city": "DASUYA",
    "yesBranchCode": "378"
  },
  "144206": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144207": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144208": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144209": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144210": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144211": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144212": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144213": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144214": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144216": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144221": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144222": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144223": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144224": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144301": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144302": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144303": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144305": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144306": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144311": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144401": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144402": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144403": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144404": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144405": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144406": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144407": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144408": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144409": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144410": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144411": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144415": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144416": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144417": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144418": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144419": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144421": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144422": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144501": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144502": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144503": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144504": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144505": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144506": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144507": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144508": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144509": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144510": {
    "city": "NAWANSHAHR",
    "yesBranchCode": "334"
  },
  "144511": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144512": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144513": {
    "city": "NAWANSHAHR",
    "yesBranchCode": "334"
  },
  "144514": {
    "city": "NAWANSHAHR",
    "yesBranchCode": "334"
  },
  "144515": {
    "city": "NAWANSHAHR",
    "yesBranchCode": "334"
  },
  "144516": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144517": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144518": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144519": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144520": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144521": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144522": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144523": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144524": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144525": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144526": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144527": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144528": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144529": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144530": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144531": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144532": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "144601": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144602": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144603": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144606": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144620": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144621": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144622": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144623": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144624": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144625": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144626": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144628": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144629": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144630": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144631": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144632": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144633": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144701": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144702": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144703": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144801": {
    "city": "JALANDHAR",
    "yesBranchCode": "71"
  },
  "144802": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144803": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144804": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144805": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144806": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "144819": {
    "city": "KAPURTHALA",
    "yesBranchCode": "275"
  },
  "145001": {
    "city": "PATHANKOT",
    "yesBranchCode": "86"
  },
  "145022": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "145023": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "145024": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "145025": {
    "city": "PATHANKOT",
    "yesBranchCode": "86"
  },
  "145026": {
    "city": "PATHANKOT",
    "yesBranchCode": "86"
  },
  "145027": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "145029": {
    "city": "GURDASPUR",
    "yesBranchCode": "956"
  },
  "145101": {
    "city": "PATHANKOT",
    "yesBranchCode": "86"
  },
  "146001": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146021": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146022": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146023": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146024": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146101": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146102": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146103": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146104": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146105": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146106": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146107": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146108": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146109": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146110": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146111": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146112": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146113": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146114": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146115": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "146116": {
    "city": "HOSHIARPUR",
    "yesBranchCode": "510"
  },
  "147001": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147002": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147003": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147004": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147005": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147006": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147007": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147021": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147101": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147102": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147103": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147104": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147105": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147111": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147201": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147202": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147203": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "147301": {
    "city": "PATIALA",
    "yesBranchCode": "57"
  },
  "148001": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148002": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148017": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148018": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148019": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148020": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148021": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148022": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148023": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148024": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148025": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148026": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148027": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148028": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148029": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148030": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148031": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148033": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148034": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148035": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148100": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148101": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148102": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148103": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148104": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148105": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148106": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148107": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148108": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "148109": {
    "city": "SANGRUR",
    "yesBranchCode": "857"
  },
  "151001": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151002": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151003": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151004": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151005": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151101": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151102": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151103": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151104": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151105": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151106": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151108": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151111": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151201": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151206": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151301": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151302": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151401": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151501": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151502": {
    "city": "BATHINDA",
    "yesBranchCode": "953"
  },
  "151503": {
    "city": "MANSA",
    "yesBranchCode": "961"
  },
  "151504": {
    "city": "MANSA",
    "yesBranchCode": "961"
  },
  "151505": {
    "city": "MANSA",
    "yesBranchCode": "961"
  },
  "151506": {
    "city": "MANSA",
    "yesBranchCode": "961"
  },
  "151507": {
    "city": "MANSA",
    "yesBranchCode": "961"
  },
  "151508": {
    "city": "MANSA",
    "yesBranchCode": "961"
  },
  "151510": {
    "city": "MANSA",
    "yesBranchCode": "961"
  },
  "152001": {
    "city": "FEROZEPUR",
    "yesBranchCode": "389"
  },
  "152026": {
    "city": "MUKTSAR",
    "yesBranchCode": "56"
  },
  "152101": {
    "city": "MUKTSAR",
    "yesBranchCode": "56"
  },
  "152107": {
    "city": "MUKTSAR",
    "yesBranchCode": "56"
  },
  "160001": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160002": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160003": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160004": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160005": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160008": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160009": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160010": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160011": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160012": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160014": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160015": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160016": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160017": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160018": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160019": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160020": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160022": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160023": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160025": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160026": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160030": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160031": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160036": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160043": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160047": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160055": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "160059": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "160061": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160062": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "160071": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "160101": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160102": {
    "city": "CHANDIGARH",
    "yesBranchCode": "145"
  },
  "160103": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "160104": {
    "city": "MOHALI",
    "yesBranchCode": "289"
  },
  "171001": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171002": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171003": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171004": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171005": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171006": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171007": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171008": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171009": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171010": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171011": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171012": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171013": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171014": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171015": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171018": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171019": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171102": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "171103": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171201": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171202": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171203": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171204": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171205": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171206": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171207": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171208": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171209": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171210": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171211": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171212": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171213": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171214": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171215": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171216": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171217": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171218": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171219": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171220": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171221": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171222": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171223": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171224": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171225": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171226": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "171301": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "172021": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "172022": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "172024": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "172027": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "172028": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "172029": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "172030": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "172031": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "172034": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "172102": {
    "city": "SHIMLA",
    "yesBranchCode": "91"
  },
  "173021": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173022": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173024": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173025": {
    "city": "SIRMAUR",
    "yesBranchCode": "353"
  },
  "173026": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173027": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173029": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173030": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173031": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173032": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173101": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173104": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173201": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173202": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173204": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173205": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173206": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173207": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173208": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173209": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173210": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173211": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173212": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173213": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173214": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173215": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173217": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173218": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173220": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173221": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173222": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173223": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173225": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173229": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173230": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173233": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173234": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173235": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "173236": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "174101": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "174102": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "174103": {
    "city": "SOLAN",
    "yesBranchCode": "353"
  },
  "174301": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174302": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174303": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174306": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174307": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174308": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174314": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174315": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174316": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174317": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174319": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174320": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174321": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174503": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "174507": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "176001": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176021": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176022": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176023": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176025": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176026": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176027": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176028": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176029": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176030": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176031": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176032": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176033": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176036": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176037": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176038": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176047": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176051": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176052": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176053": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176054": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176055": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176056": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176058": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176061": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176062": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176066": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176067": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176076": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176077": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176081": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176082": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176083": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176084": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176085": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176086": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176087": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176088": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176089": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176091": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176092": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176093": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176094": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176095": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176096": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176097": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176098": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176101": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176102": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176103": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176107": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176115": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176125": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176128": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176200": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176201": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176202": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176203": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176204": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176208": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176209": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176210": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176211": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176213": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176214": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176215": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176216": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176217": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176218": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176219": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176225": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176401": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176402": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176403": {
    "city": "DHARAMSALA",
    "yesBranchCode": "315"
  },
  "176501": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176502": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "176601": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177031": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177034": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177039": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177043": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177101": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177103": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177104": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177105": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177106": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177107": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177108": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177109": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177110": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177111": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177112": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177113": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177114": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177117": {
    "city": "KANGRA",
    "yesBranchCode": "315"
  },
  "177201": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177202": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177203": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177204": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177205": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177206": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177207": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177208": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177209": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177210": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177211": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177212": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177213": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177219": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "177220": {
    "city": "UNA",
    "yesBranchCode": "330"
  },
  "180001": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180002": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180003": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180004": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180005": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180006": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180007": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180009": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180010": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180011": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180012": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180013": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180015": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180016": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180017": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180018": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180019": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "180020": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181101": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181102": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181111": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181121": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181122": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181123": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181124": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181131": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181132": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181133": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181141": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181143": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181145": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181152": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181201": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181202": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181203": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181204": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181205": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181206": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181207": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181221": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "181224": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184102": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184104": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184120": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184121": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184141": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184142": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184143": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184145": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184148": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184151": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184152": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184201": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184203": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184204": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184205": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "184210": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "185154": {
    "city": "JAMMU",
    "yesBranchCode": "526"
  },
  "201001": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201002": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201003": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201004": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201005": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201006": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201007": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201008": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201009": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201010": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201011": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201012": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201013": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201014": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201015": {
    "city": "DASNA",
    "yesBranchCode": "266"
  },
  "201016": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201017": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201018": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201019": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201020": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201021": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201101": {
    "city": "BAGHPAT",
    "yesBranchCode": "266"
  },
  "201102": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201103": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201201": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201204": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201206": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201301": {
    "city": "NOIDA",
    "yesBranchCode": "701"
  },
  "201302": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201303": {
    "city": "NOIDA",
    "yesBranchCode": "701"
  },
  "201304": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201305": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201306": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201307": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201308": {
    "city": "NOIDA",
    "yesBranchCode": "701"
  },
  "201309": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201310": {
    "city": "GREATER NOIDA",
    "yesBranchCode": "133"
  },
  "201311": {
    "city": "GAUTAM BUDDHA NAGAR",
    "yesBranchCode": "133"
  },
  "201312": {
    "city": "GREATER NOIDA",
    "yesBranchCode": "133"
  },
  "201313": {
    "city": "GREATER NOIDA",
    "yesBranchCode": "133"
  },
  "201314": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201315": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "201316": {
    "city": "GREATER NOIDA",
    "yesBranchCode": "133"
  },
  "201317": {
    "city": "GREATER NOIDA",
    "yesBranchCode": "133"
  },
  "201318": {
    "city": "GREATER NOIDA",
    "yesBranchCode": "133"
  },
  "202001": {
    "city": "ALIGARH",
    "yesBranchCode": "116"
  },
  "202002": {
    "city": "ALIGARH",
    "yesBranchCode": "116"
  },
  "203207": {
    "city": "GAUTAM BUDDHA NAGAR",
    "yesBranchCode": "133"
  },
  "203402": {
    "city": "BULANDSHAHR",
    "yesBranchCode": "308"
  },
  "208001": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208002": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208003": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208004": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208005": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208006": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208007": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208008": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208009": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208010": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208011": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208012": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208013": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "208014": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208015": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208016": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208017": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208019": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208020": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208021": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208022": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208023": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208024": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208025": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208026": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "208027": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "209101": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209111": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209112": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209115": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209121": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209125": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209202": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209203": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209204": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209205": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "209206": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "209208": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209209": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "209210": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209214": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "209217": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209301": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209302": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209303": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209304": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "209305": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209306": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209307": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209308": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "209310": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209311": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209312": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "209401": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "209402": {
    "city": "KANPUR NAGAR",
    "yesBranchCode": "73"
  },
  "209881": {
    "city": "KANPUR DEHAT",
    "yesBranchCode": "445"
  },
  "211001": {
    "city": "ALLAHABAD",
    "yesBranchCode": "663"
  },
  "211002": {
    "city": "ALLAHABAD",
    "yesBranchCode": "663"
  },
  "211003": {
    "city": "ALLAHABAD",
    "yesBranchCode": "663"
  },
  "211004": {
    "city": "ALLAHABAD",
    "yesBranchCode": "663"
  },
  "211005": {
    "city": "ALLAHABAD",
    "yesBranchCode": "663"
  },
  "211006": {
    "city": "ALLAHABAD",
    "yesBranchCode": "663"
  },
  "211007": {
    "city": "ALLAHABAD",
    "yesBranchCode": "663"
  },
  "211008": {
    "city": "ALLAHABAD",
    "yesBranchCode": "663"
  },
  "211010": {
    "city": "ALLAHABAD",
    "yesBranchCode": "663"
  },
  "221001": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221002": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221003": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221004": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221005": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221006": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221007": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221008": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221010": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221011": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221101": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221103": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221104": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221105": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221106": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221107": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221108": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221109": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221112": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221116": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221201": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221202": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221204": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221206": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221207": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221208": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221302": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221305": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221307": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221309": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221311": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221313": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221403": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "221405": {
    "city": "VARANASI",
    "yesBranchCode": "450"
  },
  "225001": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225002": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225003": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225119": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225120": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225121": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225122": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225123": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225124": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225125": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225126": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225201": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225202": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225203": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225204": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225205": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225206": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225207": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225208": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225301": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225302": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225303": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225304": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225305": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225306": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225401": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225403": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225404": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225405": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225409": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225412": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225413": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225414": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225415": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "225416": {
    "city": "BARABANKI",
    "yesBranchCode": "209"
  },
  "226001": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226002": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226003": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226004": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226005": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226006": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226007": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226008": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226009": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226010": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226011": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226012": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226013": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226014": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226015": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226016": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226017": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226018": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226019": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226020": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226021": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226022": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226023": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226024": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226025": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226026": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226027": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226028": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226029": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226030": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226031": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226101": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226102": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226103": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226104": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226201": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226202": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226203": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226301": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226302": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226303": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226401": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "226501": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "227202": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "227205": {
    "city": "LUCKNOW",
    "yesBranchCode": "18"
  },
  "232327": {
    "city": "GHAZIPUR",
    "yesBranchCode": "110"
  },
  "243001": {
    "city": "BAREILLY",
    "yesBranchCode": "74"
  },
  "243002": {
    "city": "BAREILLY",
    "yesBranchCode": "74"
  },
  "243003": {
    "city": "BAREILLY",
    "yesBranchCode": "74"
  },
  "243004": {
    "city": "BAREILLY",
    "yesBranchCode": "74"
  },
  "243005": {
    "city": "BAREILLY",
    "yesBranchCode": "74"
  },
  "243006": {
    "city": "BAREILLY",
    "yesBranchCode": "74"
  },
  "244001": {
    "city": "MORADABAD",
    "yesBranchCode": "706"
  },
  "244102": {
    "city": "MORADABAD",
    "yesBranchCode": "706"
  },
  "244103": {
    "city": "MORADABAD",
    "yesBranchCode": "706"
  },
  "244104": {
    "city": "MORADABAD",
    "yesBranchCode": "706"
  },
  "244105": {
    "city": "MORADABAD",
    "yesBranchCode": "706"
  },
  "244712": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "244713": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "244715": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "244716": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "244717": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "245101": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "245205": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "245206": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "245207": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "245301": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "245304": {
    "city": "GHAZIABAD",
    "yesBranchCode": "38"
  },
  "247001": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247002": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247120": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247121": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247122": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247129": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247231": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247232": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247340": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247341": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247342": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247343": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247451": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247452": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247453": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247551": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247554": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247656": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "247661": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "247662": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247663": {
    "city": "HARIDWAR",
    "yesBranchCode": "610"
  },
  "247664": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "247665": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "247666": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "247667": {
    "city": "HARIDWAR",
    "yesBranchCode": "610"
  },
  "247668": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "247669": {
    "city": "SAHARANPUR",
    "yesBranchCode": "181"
  },
  "247670": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "247671": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248001": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248002": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248003": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248005": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248006": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248007": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248008": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248009": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248011": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248012": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248013": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248014": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248015": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248016": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248018": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248121": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248122": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248123": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248124": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248125": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248140": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248141": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248142": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248143": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248145": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248146": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248158": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248159": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248165": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248171": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248179": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248195": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248196": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248197": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248198": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "248199": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "249128": {
    "city": "UTTARKASHI",
    "yesBranchCode": "1"
  },
  "249135": {
    "city": "UTTARKASHI",
    "yesBranchCode": "1"
  },
  "249136": {
    "city": "UTTARKASHI",
    "yesBranchCode": "1"
  },
  "249141": {
    "city": "UTTARKASHI",
    "yesBranchCode": "1"
  },
  "249152": {
    "city": "UTTARKASHI",
    "yesBranchCode": "1"
  },
  "249171": {
    "city": "UDHAM SINGH NAGAR",
    "yesBranchCode": "1085"
  },
  "249185": {
    "city": "UTTARKASHI",
    "yesBranchCode": "1"
  },
  "249193": {
    "city": "UTTARKASHI",
    "yesBranchCode": "1"
  },
  "249194": {
    "city": "UTTARKASHI",
    "yesBranchCode": "1"
  },
  "249195": {
    "city": "UTTARKASHI",
    "yesBranchCode": "1"
  },
  "249201": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "249202": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "249203": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "249204": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "249205": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "249401": {
    "city": "HARIDWAR",
    "yesBranchCode": "610"
  },
  "249402": {
    "city": "HARIDWAR",
    "yesBranchCode": "610"
  },
  "249403": {
    "city": "HARIDWAR",
    "yesBranchCode": "610"
  },
  "249404": {
    "city": "HARIDWAR",
    "yesBranchCode": "610"
  },
  "249405": {
    "city": "HARIDWAR",
    "yesBranchCode": "610"
  },
  "249407": {
    "city": "HARIDWAR",
    "yesBranchCode": "610"
  },
  "249408": {
    "city": "HARIDWAR",
    "yesBranchCode": "610"
  },
  "249410": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "249411": {
    "city": "DEHRADUN",
    "yesBranchCode": "1068"
  },
  "250001": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250002": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250003": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250004": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250005": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250101": {
    "city": "BAGHPAT",
    "yesBranchCode": "515"
  },
  "250103": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250104": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250106": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250110": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250205": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250221": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250222": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250223": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250341": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250342": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250344": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250345": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250401": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250402": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250404": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250406": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250501": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250502": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250601": {
    "city": "BAGHPAT",
    "yesBranchCode": "108"
  },
  "250606": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250609": {
    "city": "BAGHPAT",
    "yesBranchCode": "108"
  },
  "250615": {
    "city": "BAGHPAT",
    "yesBranchCode": "384"
  },
  "250617": {
    "city": "BAGHPAT",
    "yesBranchCode": "108"
  },
  "250619": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250620": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250621": {
    "city": "BAGHPAT",
    "yesBranchCode": "108"
  },
  "250622": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250623": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "250625": {
    "city": "BAGHPAT",
    "yesBranchCode": "265"
  },
  "250626": {
    "city": "MEERUT",
    "yesBranchCode": "727"
  },
  "262308": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "262309": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "262310": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "262311": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "262401": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "262402": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "262405": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263001": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263126": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263127": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263128": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263132": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263134": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263135": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263136": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263137": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263138": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263139": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263140": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263142": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263145": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263148": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263149": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263150": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263151": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263152": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263153": {
    "city": "NAINITAL",
    "yesBranchCode": "1085"
  },
  "263156": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263157": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263158": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263159": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "263160": {
    "city": "NAINITAL",
    "yesBranchCode": "303"
  },
  "273001": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273002": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273003": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273004": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273005": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273006": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273007": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273008": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273009": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273010": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273012": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273013": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273014": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273015": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273016": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273017": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273152": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273155": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273157": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273158": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273161": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273162": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273163": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273165": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273201": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273202": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273203": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273209": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273211": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273212": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273213": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273301": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273302": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273304": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273305": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273306": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273308": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273309": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273310": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273311": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273401": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273402": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273403": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273404": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273405": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273406": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273407": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273408": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273409": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273411": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273412": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "273413": {
    "city": "GORAKHPUR",
    "yesBranchCode": "243"
  },
  "281001": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281003": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281004": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281005": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281006": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281104": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281121": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281122": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281123": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281201": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281202": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281203": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281204": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281205": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281206": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281301": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281302": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281303": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281305": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281306": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281307": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281308": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281401": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281403": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281404": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281405": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281406": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281501": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281502": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "281504": {
    "city": "MATHURA",
    "yesBranchCode": "72"
  },
  "282001": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "282002": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "282003": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "282004": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "282005": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "282006": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "282007": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "282008": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "282009": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "282010": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283101": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283102": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283104": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283105": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283110": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283111": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283112": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283113": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283114": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283115": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283119": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283121": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283122": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283123": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283124": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283125": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283126": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283201": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "283202": {
    "city": "AGRA",
    "yesBranchCode": "556"
  },
  "301001": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301002": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301018": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301019": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301020": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301021": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301022": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301023": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301024": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301025": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301026": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301027": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301028": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301030": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301035": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301401": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301402": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301403": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301404": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301405": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301406": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301407": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301408": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301409": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301410": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301411": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301412": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301413": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301414": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301415": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301416": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301427": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301604": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301701": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301702": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301703": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301704": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301705": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301706": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301707": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301708": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301709": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301713": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "301714": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "302001": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302002": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302003": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302004": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302005": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302006": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302007": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302009": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302011": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302012": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302013": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302015": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302016": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302017": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302018": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302019": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302020": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302021": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302022": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302026": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302027": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302028": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302029": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302031": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302033": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302034": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302036": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302037": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302038": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302039": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302040": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302041": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302042": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "302043": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303001": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303002": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303003": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303005": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303006": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303007": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303008": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303009": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303102": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303103": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303104": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303105": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303106": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303107": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303108": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303109": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303110": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303119": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303120": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303121": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303122": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303123": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303124": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303328": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303329": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303338": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303348": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303601": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303602": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303603": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303604": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303701": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303702": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303704": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303706": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303712": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303801": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303803": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303804": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303805": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303806": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303807": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303901": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303903": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303904": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303905": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "303906": {
    "city": "JAIPUR",
    "yesBranchCode": "24"
  },
  "305001": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305002": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305003": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305004": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305005": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305007": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305009": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305012": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305021": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305022": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305023": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305024": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305025": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305201": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305202": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305203": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305204": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305205": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305206": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305207": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305401": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305402": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305403": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305404": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305405": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305406": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305407": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305408": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305412": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305415": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305601": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305621": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305622": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305623": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305624": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305625": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305627": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305628": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305629": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305630": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305631": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305801": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305802": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305811": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305812": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305813": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305814": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305815": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305816": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305817": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305819": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305901": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305922": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305923": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305924": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "305925": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305926": {
    "city": "AJMER",
    "yesBranchCode": "605"
  },
  "305927": {
    "city": "BEAWAR",
    "yesBranchCode": "1017"
  },
  "306401": {
    "city": "PALI",
    "yesBranchCode": "641"
  },
  "307025": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "311001": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311011": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311021": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311022": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311023": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311024": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311025": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311026": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311030": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311201": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311202": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311203": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311204": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311301": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311302": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311401": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311402": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311403": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311404": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311407": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311408": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311601": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311602": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311603": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311604": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311605": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311606": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311801": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311802": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311803": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311804": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311805": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "311806": {
    "city": "BHILWARA",
    "yesBranchCode": "33"
  },
  "312202": {
    "city": "CHITTORGARH",
    "yesBranchCode": "191"
  },
  "313001": {
    "city": "UDAIPUR",
    "yesBranchCode": "651"
  },
  "313002": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313003": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313004": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313011": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313015": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313022": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313024": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313026": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313027": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313031": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313038": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313201": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313203": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313204": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313205": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313206": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313207": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313301": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313322": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313324": {
    "city": "UDAIPUR",
    "yesBranchCode": "111"
  },
  "313327": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313328": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313330": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313331": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313601": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313602": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313603": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313604": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313701": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313702": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313703": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313704": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313705": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313706": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313708": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313801": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313802": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313803": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313804": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313901": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313902": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313903": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313904": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313905": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "313906": {
    "city": "UDAIPUR",
    "yesBranchCode": "294"
  },
  "321605": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "321606": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "321607": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "321633": {
    "city": "ALWAR",
    "yesBranchCode": "114"
  },
  "324001": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "324002": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "324003": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "324004": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "324005": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "324006": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "324007": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "324008": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "324009": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "324010": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325001": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325003": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325004": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325009": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325201": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325202": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325203": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325204": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325207": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325208": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325209": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325214": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325216": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325217": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325219": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325222": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325223": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325601": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "325602": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "326517": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "326518": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "326519": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "326520": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "326529": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "326530": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "328216": {
    "city": "KOTA",
    "yesBranchCode": "393"
  },
  "333031": {
    "city": "JHUNJHUNU",
    "yesBranchCode": "126"
  },
  "342001": {
    "city": "JODHPUR",
    "yesBranchCode": "938"
  },
  "342003": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342005": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342006": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342007": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342008": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342011": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342012": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342013": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342014": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342015": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342021": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342022": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342023": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342024": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342025": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342026": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342027": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342028": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342029": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342037": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342301": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342302": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342303": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342304": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342305": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342306": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342307": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342308": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342309": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342310": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342311": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342312": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342314": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342601": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342602": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342603": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342604": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342605": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342606": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342801": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342802": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "342901": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "345022": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "345023": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "345024": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "345025": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "345026": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "345028": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "345031": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "345033": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "345034": {
    "city": "JODHPUR",
    "yesBranchCode": "41"
  },
  "360001": {
    "city": "RAJKOT",
    "yesBranchCode": "98"
  },
  "360002": {
    "city": "RAJKOT",
    "yesBranchCode": "98"
  },
  "360003": {
    "city": "RAJKOT",
    "yesBranchCode": "98"
  },
  "360004": {
    "city": "RAJKOT",
    "yesBranchCode": "98"
  },
  "360005": {
    "city": "RAJKOT",
    "yesBranchCode": "98"
  },
  "360006": {
    "city": "RAJKOT",
    "yesBranchCode": "98"
  },
  "360007": {
    "city": "RAJKOT",
    "yesBranchCode": "98"
  },
  "361001": {
    "city": "JAMNAGAR",
    "yesBranchCode": "533"
  },
  "361002": {
    "city": "JAMNAGAR",
    "yesBranchCode": "533"
  },
  "361003": {
    "city": "JAMNAGAR",
    "yesBranchCode": "533"
  },
  "361004": {
    "city": "JAMNAGAR",
    "yesBranchCode": "533"
  },
  "361005": {
    "city": "JAMNAGAR",
    "yesBranchCode": "533"
  },
  "361006": {
    "city": "JAMNAGAR",
    "yesBranchCode": "533"
  },
  "361007": {
    "city": "JAMNAGAR",
    "yesBranchCode": "533"
  },
  "361008": {
    "city": "JAMNAGAR",
    "yesBranchCode": "533"
  },
  "361009": {
    "city": "JAMNAGAR",
    "yesBranchCode": "533"
  },
  "362001": {
    "city": "JUNAGADH",
    "yesBranchCode": "1060"
  },
  "362002": {
    "city": "JUNAGADH",
    "yesBranchCode": "1060"
  },
  "362011": {
    "city": "JUNAGADH",
    "yesBranchCode": "1060"
  },
  "362015": {
    "city": "JUNAGADH",
    "yesBranchCode": "1060"
  },
  "362265": {
    "city": "JUNAGADH",
    "yesBranchCode": "1060"
  },
  "362520": {
    "city": "DIU",
    "yesBranchCode": "20"
  },
  "362540": {
    "city": "JUNAGADH",
    "yesBranchCode": "1060"
  },
  "363001": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363002": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363020": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363030": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363035": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363040": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363110": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363115": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363310": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363320": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363330": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363351": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363410": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363415": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363421": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363423": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363425": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363427": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363430": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363435": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363440": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363510": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363520": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363530": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363635": {
    "city": "RAJKOT",
    "yesBranchCode": "98"
  },
  "363641": {
    "city": "RAJKOT",
    "yesBranchCode": "98"
  },
  "363642": {
    "city": "MORBI",
    "yesBranchCode": "726"
  },
  "363745": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363750": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363755": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363760": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363765": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363775": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "363780": {
    "city": "SURENDRA NAGAR",
    "yesBranchCode": "1016"
  },
  "364001": {
    "city": "BHAVNAGAR",
    "yesBranchCode": "64"
  },
  "364002": {
    "city": "BHAVNAGAR",
    "yesBranchCode": "64"
  },
  "364003": {
    "city": "BHAVNAGAR",
    "yesBranchCode": "64"
  },
  "364004": {
    "city": "BHAVNAGAR",
    "yesBranchCode": "64"
  },
  "364005": {
    "city": "BHAVNAGAR",
    "yesBranchCode": "64"
  },
  "364006": {
    "city": "BHAVNAGAR",
    "yesBranchCode": "64"
  },
  "365601": {
    "city": "AMRELI",
    "yesBranchCode": "934"
  },
  "370001": {
    "city": "BHUJ",
    "yesBranchCode": "548"
  },
  "370015": {
    "city": "KACHCHH",
    "yesBranchCode": "548"
  },
  "370020": {
    "city": "KACHCHH",
    "yesBranchCode": "548"
  },
  "370040": {
    "city": "KACHCHH",
    "yesBranchCode": "548"
  },
  "370201": {
    "city": "GANDHIDHAM",
    "yesBranchCode": "548"
  },
  "370203": {
    "city": "GANDHIDHAM",
    "yesBranchCode": "548"
  },
  "370205": {
    "city": "GANDHIDHAM",
    "yesBranchCode": "548"
  },
  "370210": {
    "city": "KACHCHH",
    "yesBranchCode": "548"
  },
  "370230": {
    "city": "RAJKOT",
    "yesBranchCode": "98"
  },
  "370240": {
    "city": "KACHCHH",
    "yesBranchCode": "548"
  },
  "380001": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380002": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380003": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380004": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380005": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380006": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380007": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380008": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380009": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380012": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "380013": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380014": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380015": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380016": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380018": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380019": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380021": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380022": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380023": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380024": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380025": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380026": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380027": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380028": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380049": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380050": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380051": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380052": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380054": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380055": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380058": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380059": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380060": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380061": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "380063": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382006": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382007": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382010": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382016": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382021": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382024": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382028": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382030": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382041": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382042": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382045": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382110": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382115": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382140": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382145": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382210": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382220": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382225": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382260": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382305": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382325": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382330": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382340": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382345": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382350": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382355": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382405": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382410": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382415": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382418": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382421": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382422": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382424": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382425": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382426": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382427": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382428": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382430": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382433": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382435": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382440": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382443": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382445": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382449": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382470": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382475": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382480": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382481": {
    "city": "AHMEDABAD",
    "yesBranchCode": "621"
  },
  "382610": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382620": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382630": {
    "city": "RUPAL",
    "yesBranchCode": "186"
  },
  // "382715": {
  //    "city": "KADI",
  //    "yesBranchCode": "1121"
  // },
  "382715": {
    "city": "CHHATRAL",
    "yesBranchCode": "1039"
  },
  "382721": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382725": {
    "city": "GANDHI NAGAR",
    "yesBranchCode": "186"
  },
  "382729": {
    "city": "CHHATRAL",
    "yesBranchCode": "1039"
  },
  "382850": {
    "city": "VIJAPUR",
    "yesBranchCode": "332"
  },
  "382870": {
    "city": "VIJAPUR",
    "yesBranchCode": "332"
  },
  "384001": {
    "city": "MAHESANA",
    "yesBranchCode": "638"
  },
  "384002": {
    "city": "MAHESANA",
    "yesBranchCode": "638"
  },
  "384003": {
    "city": "MAHESANA",
    "yesBranchCode": "638"
  },
  "384170": {
    "city": "MAHESANA",
    "yesBranchCode": "638"
  },
  "384315": {
    "city": "MAHESANA",
    "yesBranchCode": "638"
  },
  "384355": {
    "city": "MAHESANA",
    "yesBranchCode": "638"
  },
  "385535": {
    "city": "BANASKANTHA",
    "yesBranchCode": "392"
  },
  "385540": {
    "city": "BANASKANTHA",
    "yesBranchCode": "392"
  },
  "387001": {
    "city": "KHEDA",
    "yesBranchCode": "739"
  },
  "387002": {
    "city": "KHEDA",
    "yesBranchCode": "739"
  },
  "387003": {
    "city": "KHEDA",
    "yesBranchCode": "739"
  },
  "387310": {
    "city": "ANAND",
    "yesBranchCode": "194"
  },
  "388001": {
    "city": "ANAND",
    "yesBranchCode": "194"
  },
  "388110": {
    "city": "ANAND",
    "yesBranchCode": "194"
  },
  "388120": {
    "city": "ANAND",
    "yesBranchCode": "194"
  },
  "388121": {
    "city": "ANAND",
    "yesBranchCode": "194"
  },
  "388205": {
    "city": "ANAND",
    "yesBranchCode": "194"
  },
  "388210": {
    "city": "ANAND",
    "yesBranchCode": "194"
  },
  "388215": {
    "city": "ANAND",
    "yesBranchCode": "194"
  },
  "388320": {
    "city": "ANAND",
    "yesBranchCode": "194"
  },
  "388540": {
    "city": "ANAND",
    "yesBranchCode": "194"
  },
  "389350": {
    "city": "PANCH MAHALS",
    "yesBranchCode": "255"
  },
  "390001": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390002": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390003": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390004": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390005": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390006": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390007": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390008": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390009": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390010": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390011": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390012": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390013": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390014": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390015": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390016": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390017": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390018": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390019": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390020": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390021": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390022": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390023": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390024": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "390025": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391105": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391110": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391135": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391243": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391310": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391320": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391330": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391340": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391345": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391346": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391350": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391410": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391421": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391440": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391740": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391750": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391770": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391775": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391776": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "391780": {
    "city": "VADODARA",
    "yesBranchCode": "935"
  },
  "392001": {
    "city": "BHARUCH",
    "yesBranchCode": "245"
  },
  "392011": {
    "city": "BHARUCH",
    "yesBranchCode": "245"
  },
  "392015": {
    "city": "BHARUCH",
    "yesBranchCode": "245"
  },
  "393001": {
    "city": "BHARUCH",
    "yesBranchCode": "245"
  },
  "393002": {
    "city": "BHARUCH",
    "yesBranchCode": "245"
  },
  "393010": {
    "city": "BHARUCH",
    "yesBranchCode": "245"
  },
  "394101": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394105": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394107": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394110": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394111": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394115": {
    "city": "BHARUCH",
    "yesBranchCode": "245"
  },
  "394116": {
    "city": "BHARUCH",
    "yesBranchCode": "245"
  },
  "394120": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394130": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394150": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394155": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394180": {
    "city": "BARDOLI",
    "yesBranchCode": "400"
  },
  "394185": {
    "city": "BARDOLI",
    "yesBranchCode": "400"
  },
  "394190": {
    "city": "BARDOLI",
    "yesBranchCode": "400"
  },
  "394210": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394221": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394230": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394235": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394270": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394305": {
    "city": "BARDOLI",
    "yesBranchCode": "400"
  },
  "394327": {
    "city": "BARDOLI",
    "yesBranchCode": "400"
  },
  "394330": {
    "city": "BARDOLI",
    "yesBranchCode": "400"
  },
  "394510": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394515": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394516": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394517": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394518": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394520": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394550": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "394601": {
    "city": "BARDOLI",
    "yesBranchCode": "400"
  },
  "395001": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395002": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395003": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395004": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395005": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395006": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395007": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395008": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395009": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395010": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395011": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395012": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395013": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395017": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "395023": {
    "city": "SURAT",
    "yesBranchCode": "400"
  },
  "396001": {
    "city": "VALSAD",
    "yesBranchCode": "691"
  },
  "396002": {
    "city": "VALSAD",
    "yesBranchCode": "691"
  },
  "396007": {
    "city": "VALSAD",
    "yesBranchCode": "691"
  },
  "396020": {
    "city": "VALSAD",
    "yesBranchCode": "691"
  },
  "396030": {
    "city": "VALSAD",
    "yesBranchCode": "691"
  },
  "396035": {
    "city": "VALSAD",
    "yesBranchCode": "691"
  },
  "396040": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396045": {
    "city": "VALSAD",
    "yesBranchCode": "691"
  },
  "396050": {
    "city": "VALSAD",
    "yesBranchCode": "691"
  },
  "396060": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396065": {
    "city": "VALSAD",
    "yesBranchCode": "691"
  },
  "396067": {
    "city": "VALSAD",
    "yesBranchCode": "691"
  },
  "396145": {
    "city": "VAPI",
    "yesBranchCode": "333"
  },
  "396155": {
    "city": "VALSAD",
    "yesBranchCode": "691"
  },
  "396185": {
    "city": "VAPI",
    "yesBranchCode": "333"
  },
  "396191": {
    "city": "VALSAD",
    "yesBranchCode": "691"
  },
  "396193": {
    "city": "DADRA AND HAVELI",
    "yesBranchCode": "256"
  },
  "396195": {
    "city": "VAPI",
    "yesBranchCode": "333"
  },
  "396210": {
    "city": "DAMAN",
    "yesBranchCode": "333"
  },
  "396215": {
    "city": "VAPI",
    "yesBranchCode": "333"
  },
  "396220": {
    "city": "VAPI",
    "yesBranchCode": "333"
  },
  "396230": {
    "city": "SILVASSA",
    "yesBranchCode": "256"
  },
  "396240": {
    "city": "SILVASSA",
    "yesBranchCode": "256"
  },
  "396325": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396380": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396421": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396424": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396427": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396430": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396433": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396436": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396439": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396440": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396445": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396450": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "396521": {
    "city": "NAVSARI",
    "yesBranchCode": "362"
  },
  "400001": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400002": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400003": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400004": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400005": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400006": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400007": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400008": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400009": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400010": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400011": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400012": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400013": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400014": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400015": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400016": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400017": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400018": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400019": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400020": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400021": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400022": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400023": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400024": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400025": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400026": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400027": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400028": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400029": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400030": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400031": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400032": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400033": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400034": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400035": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400036": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400037": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400038": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400039": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400041": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400042": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400043": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400049": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400050": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400051": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400052": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400053": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400054": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400055": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400056": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400057": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400058": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400059": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400060": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400061": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400062": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400063": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400064": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400065": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400066": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400067": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400068": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400069": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400070": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400071": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400072": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400074": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400075": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400076": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400077": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400078": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400079": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400080": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400081": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400082": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400083": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400084": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400085": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400086": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400087": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400088": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400089": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400090": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400091": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400092": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400093": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400094": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400095": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400096": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400097": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400098": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400099": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400101": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400102": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400103": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400104": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400601": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "400602": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "400603": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "400604": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "400605": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "400606": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "400607": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "400608": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "400609": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400610": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "400611": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400612": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "400613": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "400614": {
    "city": "NAVI MUMBAI",
    "yesBranchCode": "216"
  },
  "400615": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "400701": {
    "city": "NAVI MUMBAI",
    "yesBranchCode": "216"
  },
  "400703": {
    "city": "NAVI MUMBAI",
    "yesBranchCode": "216"
  },
  "400705": {
    "city": "NAVI MUMBAI",
    "yesBranchCode": "216"
  },
  "400706": {
    "city": "NAVI MUMBAI",
    "yesBranchCode": "216"
  },
  "400708": {
    "city": "NAVI MUMBAI",
    "yesBranchCode": "216"
  },
  "400709": {
    "city": "NAVI MUMBAI",
    "yesBranchCode": "216"
  },
  "400710": {
    "city": "NAVI MUMBAI",
    "yesBranchCode": "216"
  },
  "401101": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401102": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401103": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401105": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401107": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401201": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401202": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401203": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401204": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401206": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401207": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401208": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401209": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401301": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401302": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401303": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401304": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401305": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401402": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401404": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401501": {
    "city": "BOISAR",
    "yesBranchCode": "52"
  },
  "401502": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401503": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401504": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "401506": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "402107": {
    "city": "PEN",
    "yesBranchCode": "377"
  },
  "403001": {
    "city": "PANAJI",
    "yesBranchCode": "27"
  },
  "403002": {
    "city": "PANJIM",
    "yesBranchCode": "27"
  },
  "403003": {
    "city": "PANAJI",
    "yesBranchCode": "27"
  },
  "403004": {
    "city": "PANAJI",
    "yesBranchCode": "27"
  },
  "403005": {
    "city": "TISWADI",
    "yesBranchCode": "27"
  },
  "403006": {
    "city": "TISWADI",
    "yesBranchCode": "27"
  },
  "403101": {
    "city": "PORUORIM",
    "yesBranchCode": "27"
  },
  // "403101": {
  //    "city": "BARDEZ",
  //    "yesBranchCode": "233"
  // },
  "403102": {
    "city": "TISWADI",
    "yesBranchCode": "27"
  },
  "403103": {
    "city": "SALCETE",
    "yesBranchCode": "360"
  },
  "403104": {
    "city": "TISWADI",
    "yesBranchCode": "27"
  },
  "403105": {
    "city": "SATARI",
    "yesBranchCode": "356"
  },
  "403106": {
    "city": "PONDA",
    "yesBranchCode": "356"
  },
  "403107": {
    "city": "PONDA",
    "yesBranchCode": "356"
  },
  "403108": {
    "city": "TISWADI",
    "yesBranchCode": "27"
  },
  "403109": {
    "city": "TISWADI",
    "yesBranchCode": "27"
  },
  "403110": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403114": {
    "city": "CALANGUTE",
    "yesBranchCode": "257"
  },
  "403115": {
    "city": "PONDA",
    "yesBranchCode": "356"
  },
  "403201": {
    "city": "TISWADI",
    "yesBranchCode": "27"
  },
  "403202": {
    "city": "TISWADI",
    "yesBranchCode": "27"
  },
  "403203": {
    "city": "TISWADI",
    "yesBranchCode": "27"
  },
  "403204": {
    "city": "TISWADI",
    "yesBranchCode": "27"
  },
  "403206": {
    "city": "TISWADI",
    "yesBranchCode": "27"
  },
  // "403206": {
  //    "city": "PANAJI",
  //    "yesBranchCode": "27"
  // },
  "403401": {
    "city": "PONDA",
    "yesBranchCode": "356"
  },
  "403402": {
    "city": "TISWADI",
    "yesBranchCode": "27"
  },
  "403403": {
    "city": "TISWADI",
    "yesBranchCode": "27"
  },
  "403404": {
    "city": "PONDA",
    "yesBranchCode": "356"
  },
  "403406": {
    "city": "DHARBANDORA",
    "yesBranchCode": "356"
  },
  "403409": {
    "city": "PONDA",
    "yesBranchCode": "356"
  },
  "403410": {
    "city": "SANGUEM",
    "yesBranchCode": "380"
  },
  "403501": {
    "city": "PORUORIM",
    "yesBranchCode": "27"
  },
  "403502": {
    "city": "TIVIM",
    "yesBranchCode": "233"
  },
  "403503": {
    "city": "PERNEM",
    "yesBranchCode": "233"
  },
  "403504": {
    "city": "BICHOLIM",
    "yesBranchCode": "233"
  },
  "403505": {
    "city": "BICHOLIM",
    "yesBranchCode": "233"
  },
  "403506": {
    "city": "SATARI",
    "yesBranchCode": "356"
  },
  "403507": {
    "city": "BARDEZ",
    "yesBranchCode": "233"
  },
  "403508": {
    "city": "BARDEZ",
    "yesBranchCode": "233"
  },
  "403509": {
    "city": "BARDEZ",
    "yesBranchCode": "233"
  },
  "403510": {
    "city": "BARDEZ",
    "yesBranchCode": "233"
  },
  "403511": {
    "city": "BARDEZ",
    "yesBranchCode": "233"
  },
  "403512": {
    "city": "PERNEM",
    "yesBranchCode": "233"
  },
  "403513": {
    "city": "TIVIM",
    "yesBranchCode": "233"
  },
  "403515": {
    "city": "CALANGUTE",
    "yesBranchCode": "257"
  },
  "403516": {
    "city": "CALANGUTE",
    "yesBranchCode": "257"
  },
  "403517": {
    "city": "TIVIM",
    "yesBranchCode": "233"
  },
  // "403517": {
  //    "city": "MAPUSA",
  //    "yesBranchCode": "233"
  // },
  // "403519": {
  //    "city": "MAPUSA",
  //    "yesBranchCode": "233"
  // },
  "403519": {
    "city": "CALANGUTE",
    "yesBranchCode": "257"
  },
  "403521": {
    "city": "PANAJI",
    "yesBranchCode": "27"
  },
  "403523": {
    "city": "BARDEZ",
    "yesBranchCode": "233"
  },
  "403524": {
    "city": "PERNEM",
    "yesBranchCode": "233"
  },
  "403526": {
    "city": "TIVIM",
    "yesBranchCode": "233"
  },
  "403527": {
    "city": "PERNEM",
    "yesBranchCode": "233"
  },
  "403529": {
    "city": "BICHOLIM",
    "yesBranchCode": "233"
  },
  "403530": {
    "city": "SATARI",
    "yesBranchCode": "356"
  },
  "403601": {
    "city": "MARGAO",
    "yesBranchCode": "19"
  },
  // "403601": {
  //    "city": "MARGAO",
  //    "yesBranchCode": "19"
  // },
  // "403601": {
  //    "city": "MADGAON",
  //    "yesBranchCode": "19"
  // },
  // "403601": {
  //    "city": "NAVELIM",
  //    "yesBranchCode": "19"
  // },
  // "403602": {
  //    "city": "MARGAO",
  //    "yesBranchCode": "19"
  // },
  "403602": {
    "city": "MADGAON",
    "yesBranchCode": "19"
  },
  "403701": {
    "city": "SALCETE",
    "yesBranchCode": "360"
  },
  "403702": {
    "city": "CANACONA",
    "yesBranchCode": "360"
  },
  "403703": {
    "city": "SALCETE",
    "yesBranchCode": "360"
  },
  "403704": {
    "city": "SANGUEM",
    "yesBranchCode": "380"
  },
  "403705": {
    "city": "QUEPEM",
    "yesBranchCode": "380"
  },
  "403706": {
    "city": "QUEPEM",
    "yesBranchCode": "380"
  },
  // "403707": {
  //    "city": "MARGAO",
  //    "yesBranchCode": "19"
  // },
  // "403707": {
  //    "city": "MADGAON",
  //    "yesBranchCode": "19"
  // },
  "403707": {
    "city": "NAVELIM",
    "yesBranchCode": "19"
  },
  "403708": {
    "city": "SALCETE",
    "yesBranchCode": "360"
  },
  "403709": {
    "city": "MARGAO",
    "yesBranchCode": "19"
  },
  // "403709": {
  //    "city": "MADGAON",
  //    "yesBranchCode": "19"
  // },
  "403710": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403711": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  // "403711": {
  //    "city": "VASCODAGAMA",
  //    "yesBranchCode": "662"
  // },
  "403712": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403713": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403714": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  // "403714": {
  //    "city": "QUEPEM",
  //    "yesBranchCode": "380"
  // },
  // "403714": {
  //    "city": "MARGAO",
  //    "yesBranchCode": "19"
  // },
  // "403714": {
  //    "city": "MADGAON",
  //    "yesBranchCode": "19"
  // },
  "403715": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403716": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403717": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403718": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403719": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403720": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  // "403720": {
  //    "city": "MARGAO",
  //    "yesBranchCode": "19"
  // },
  // "403720": {
  //    "city": "MADGAON",
  //    "yesBranchCode": "19"
  // },
  // "403721": {
  //    "city": "VARCA",
  //    "yesBranchCode": "360"
  // },
  "403721": {
    "city": "NAVELIM",
    "yesBranchCode": "19"
  },
  "403722": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403723": {
    "city": "SALCETE",
    "yesBranchCode": "360"
  },
  "403724": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403725": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403726": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403728": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403729": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  // "403729": {
  //    "city": "NAVELIM",
  //    "yesBranchCode": "19"
  // },
  "403731": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  "403801": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  // "403801": {
  //    "city": "VASCODAGAMA",
  //    "yesBranchCode": "662"
  // },
  // "403801": {
  //    "city": "MORMUGAO",
  //    "yesBranchCode": "662"
  // },
  "403802": {
    "city": "GOA",
    "yesBranchCode": "27"
  },
  // "403802": {
  //    "city": "VASCODAGAMA",
  //    "yesBranchCode": "662"
  // },
  // "403803": {
  //    "city": "MORMUGAO",
  //    "yesBranchCode": "662"
  // },
  "403803": {
    "city": "VASCODAGAMA",
    "yesBranchCode": "662"
  },
  // "403804": {
  //    "city": "GOA",
  //    "yesBranchCode": "27"
  // },
  "403804": {
    "city": "VASCODAGAMA",
    "yesBranchCode": "662"
  },
  // "403806": {
  //    "city": "GOA",
  //    "yesBranchCode": "27"
  // },
  "403806": {
    "city": "VASCODAGAMA",
    "yesBranchCode": "662"
  },
  "410203": {
    "city": "NAVI MUMBAI",
    "yesBranchCode": "216"
  },
  "410206": {
    "city": "NAVI MUMBAI",
    "yesBranchCode": "216"
  },
  "410210": {
    "city": "NAVI MUMBAI",
    "yesBranchCode": "216"
  },
  "410218": {
    "city": "NAVI MUMBAI",
    "yesBranchCode": "216"
  },
  "410219": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "411001": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411002": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411003": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411004": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411005": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411006": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411007": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411008": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411009": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411011": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411012": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411013": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411014": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411015": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411016": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411017": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411018": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411019": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411020": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411021": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411022": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411024": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411026": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411027": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411028": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411030": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411031": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411032": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411033": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411034": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411035": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411036": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411037": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411038": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411039": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411040": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411041": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411042": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411043": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411044": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411045": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411046": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411047": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411048": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411051": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411052": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411057": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411058": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411060": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411061": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411062": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "411067": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "412112": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "412114": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "412210": {
    "city": "SHIRUR",
    "yesBranchCode": "921"
  },
  "412307": {
    "city": "PUNE",
    "yesBranchCode": "860"
  },
  "416001": {
    "city": "KOLHAPUR",
    "yesBranchCode": "1034"
  },
  "416002": {
    "city": "KOLHAPUR",
    "yesBranchCode": "1034"
  },
  "416003": {
    "city": "KOLHAPUR",
    "yesBranchCode": "1034"
  },
  "416004": {
    "city": "KOLHAPUR",
    "yesBranchCode": "1034"
  },
  "416005": {
    "city": "KOLHAPUR",
    "yesBranchCode": "1034"
  },
  "416006": {
    "city": "KOLHAPUR",
    "yesBranchCode": "1034"
  },
  "416007": {
    "city": "KOLHAPUR",
    "yesBranchCode": "1034"
  },
  "416008": {
    "city": "KOLHAPUR",
    "yesBranchCode": "1034"
  },
  "416010": {
    "city": "KOLHAPUR",
    "yesBranchCode": "1034"
  },
  "416012": {
    "city": "KOLHAPUR",
    "yesBranchCode": "1034"
  },
  "416013": {
    "city": "KOLHAPUR",
    "yesBranchCode": "1034"
  },
  "416119": {
    "city": "KOLHAPUR",
    "yesBranchCode": "1034"
  },
  "421001": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421002": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421004": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421005": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421103": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421201": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421202": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421203": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421204": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421301": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421302": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421304": {
    "city": "MUMBAI",
    "yesBranchCode": "82"
  },
  "421305": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421306": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421308": {
    "city": "BHIWANDI",
    "yesBranchCode": "177"
  },
  "421311": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421501": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421502": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421503": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421505": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "421506": {
    "city": "THANE",
    "yesBranchCode": "77"
  },
  "422001": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422002": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422003": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422004": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422005": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422006": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422007": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422008": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422009": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422010": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422011": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422012": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422013": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422101": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422102": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "422401": {
    "city": "NASHIK",
    "yesBranchCode": "21"
  },
  "431001": {
    "city": "AURANGABAD",
    "yesBranchCode": "339"
  },
  "431002": {
    "city": "AURANGABAD",
    "yesBranchCode": "339"
  },
  "431003": {
    "city": "AURANGABAD",
    "yesBranchCode": "339"
  },
  "431004": {
    "city": "AURANGABAD",
    "yesBranchCode": "339"
  },
  "431005": {
    "city": "AURANGABAD",
    "yesBranchCode": "339"
  },
  "431006": {
    "city": "AURANGABAD",
    "yesBranchCode": "339"
  },
  "431007": {
    "city": "AURANGABAD",
    "yesBranchCode": "339"
  },
  "431008": {
    "city": "AURANGABAD",
    "yesBranchCode": "339"
  },
  "431009": {
    "city": "AURANGABAD",
    "yesBranchCode": "339"
  },
  "431117": {
    "city": "AURANGABAD",
    "yesBranchCode": "339"
  },
  "431133": {
    "city": "AURANGABAD",
    "yesBranchCode": "339"
  },
  "440001": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440002": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440003": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440004": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440005": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440006": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440007": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440008": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440009": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440010": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440012": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440013": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440014": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440015": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440016": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440017": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440018": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440019": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440020": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440021": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440022": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440024": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440025": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440027": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440030": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440032": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440033": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440034": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440035": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440036": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "440037": {
    "city": "NAGPUR",
    "yesBranchCode": "28"
  },
  "444604": {
    "city": "AMRAVATI",
    "yesBranchCode": "546"
  },
  "444605": {
    "city": "AMRAVATI",
    "yesBranchCode": "546"
  },
  "444606": {
    "city": "AMRAVATI",
    "yesBranchCode": "546"
  },
  "444607": {
    "city": "AMRAVATI",
    "yesBranchCode": "546"
  },
  "452001": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452002": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452003": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452005": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452006": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452007": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452008": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452009": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452010": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452011": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452012": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452013": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452014": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452015": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452016": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452018": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "452020": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "453441": {
    "city": "INDORE",
    "yesBranchCode": "40"
  },
  "454775": {
    "city": "PITHAMPUR",
    "yesBranchCode": "679"
  },
  "455001": {
    "city": "DEWAS",
    "yesBranchCode": "206"
  },
  "455111": {
    "city": "DEWAS",
    "yesBranchCode": "206"
  },
  "456001": {
    "city": "UJJAIN",
    "yesBranchCode": "381"
  },
  "456003": {
    "city": "UJJAIN",
    "yesBranchCode": "381"
  },
  "456006": {
    "city": "UJJAIN",
    "yesBranchCode": "381"
  },
  "456010": {
    "city": "UJJAIN",
    "yesBranchCode": "381"
  },
  "457001": {
    "city": "RATLAM",
    "yesBranchCode": "1004"
  },
  "461001": {
    "city": "HOSHANGABAD",
    "yesBranchCode": "672"
  },
  "461005": {
    "city": "HOSHANGABAD",
    "yesBranchCode": "672"
  },
  "461110": {
    "city": "HOSHANGABAD",
    "yesBranchCode": "672"
  },
  "461881": {
    "city": "HOSHANGABAD",
    "yesBranchCode": "672"
  },
  "462001": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462002": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462003": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462004": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462008": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462010": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462011": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462016": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462020": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462022": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462023": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462024": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462026": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462027": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462030": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462031": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462033": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462036": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462037": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462038": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462039": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462041": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462042": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462043": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462044": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462045": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462046": {
    "city": "RAISEN",
    "yesBranchCode": "358"
  },
  "462047": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462066": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "462100": {
    "city": "BHOPAL",
    "yesBranchCode": "474"
  },
  "464001": {
    "city": "VIDISHA",
    "yesBranchCode": "1136"
  },
  "464551": {
    "city": "RAISEN",
    "yesBranchCode": "508"
  },
  "464570": {
    "city": "RAISEN",
    "yesBranchCode": "508"
  },
  "464668": {
    "city": "RAISEN",
    "yesBranchCode": "767"
  },
  "464671": {
    "city": "RAISEN",
    "yesBranchCode": "767"
  },
  "464774": {
    "city": "RAISEN",
    "yesBranchCode": "1146"
  },
  "464776": {
    "city": "RAISEN",
    "yesBranchCode": "767"
  },
  "464881": {
    "city": "RAISEN",
    "yesBranchCode": "1055"
  },
  "464886": {
    "city": "RAISEN",
    "yesBranchCode": "767"
  },
  "464993": {
    "city": "RAISEN",
    "yesBranchCode": "358"
  },
  "466001": {
    "city": "SEHORE",
    "yesBranchCode": "411"
  },
  "471318": {
    "city": "CHHATARPUR",
    "yesBranchCode": "1055"
  },
  "474001": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "474002": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "474003": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "474004": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "474005": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "474006": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "474007": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "474008": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "474009": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "474010": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "474011": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "474012": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "474015": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "474020": {
    "city": "GWALIOR",
    "yesBranchCode": "554"
  },
  "482001": {
    "city": "JABALPUR",
    "yesBranchCode": "444"
  },
  "482002": {
    "city": "JABALPUR",
    "yesBranchCode": "444"
  },
  "482003": {
    "city": "JABALPUR",
    "yesBranchCode": "444"
  },
  "482004": {
    "city": "JABALPUR",
    "yesBranchCode": "444"
  },
  "482005": {
    "city": "JABALPUR",
    "yesBranchCode": "444"
  },
  "482008": {
    "city": "JABALPUR",
    "yesBranchCode": "444"
  },
  "482009": {
    "city": "JABALPUR",
    "yesBranchCode": "444"
  },
  "482010": {
    "city": "JABALPUR",
    "yesBranchCode": "444"
  },
  "482011": {
    "city": "JABALPUR",
    "yesBranchCode": "444"
  },
  "482020": {
    "city": "JABALPUR",
    "yesBranchCode": "444"
  },
  "482021": {
    "city": "JABALPUR",
    "yesBranchCode": "444"
  },
  "486001": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486002": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486003": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486004": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486005": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486006": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486111": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486114": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486115": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486117": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486123": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486220": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486223": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486226": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486331": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486333": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486335": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486338": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486340": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486341": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486440": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486441": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486445": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486446": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486447": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486448": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486450": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486550": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486553": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "486556": {
    "city": "REWA",
    "yesBranchCode": "663"
  },
  "490001": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "490006": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "490009": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "490011": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "490020": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "490021": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "490022": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "490023": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "490024": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "490025": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "490026": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "490036": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "490042": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "491001": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "491107": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "491111": {
    "city": "DURG",
    "yesBranchCode": "67"
  },
  "492001": {
    "city": "RAIPUR",
    "yesBranchCode": "1027"
  },
  "492002": {
    "city": "RAIPUR",
    "yesBranchCode": "1027"
  },
  "492003": {
    "city": "RAIPUR",
    "yesBranchCode": "1027"
  },
  "492004": {
    "city": "RAIPUR",
    "yesBranchCode": "1027"
  },
  "492005": {
    "city": "RAIPUR",
    "yesBranchCode": "1027"
  },
  "492008": {
    "city": "RAIPUR",
    "yesBranchCode": "1027"
  },
  "492009": {
    "city": "RAIPUR",
    "yesBranchCode": "1027"
  },
  "492010": {
    "city": "RAIPUR",
    "yesBranchCode": "1027"
  },
  "492013": {
    "city": "RAIPUR",
    "yesBranchCode": "1027"
  },
  "492015": {
    "city": "RAIPUR",
    "yesBranchCode": "1027"
  },
  "495001": {
    "city": "BILASPUR CT",
    "yesBranchCode": "527"
  },
  "495003": {
    "city": "BILASPUR CT",
    "yesBranchCode": "527"
  },
  "495004": {
    "city": "BILASPUR CT",
    "yesBranchCode": "527"
  },
  "495006": {
    "city": "BILASPUR CT",
    "yesBranchCode": "527"
  },
  "495009": {
    "city": "BILASPUR CT",
    "yesBranchCode": "527"
  },
  "495112": {
    "city": "BILASPUR CT",
    "yesBranchCode": "527"
  },
  "495113": {
    "city": "BILASPUR CT",
    "yesBranchCode": "527"
  },
  "495115": {
    "city": "BILASPUR CT",
    "yesBranchCode": "527"
  },
  "495116": {
    "city": "BILASPUR CT",
    "yesBranchCode": "527"
  },
  "495117": {
    "city": "BILASPUR CT",
    "yesBranchCode": "527"
  },
  "495118": {
    "city": "BILASPUR CT",
    "yesBranchCode": "527"
  },
  "495119": {
    "city": "BILASPUR CT",
    "yesBranchCode": "527"
  },
  "500001": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500003": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500004": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500006": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500007": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500008": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500009": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500010": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500011": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500013": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500014": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500015": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500016": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500017": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500018": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500019": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500020": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500022": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500025": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500026": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500027": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500028": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500029": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500032": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500033": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500034": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500035": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500036": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500037": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500038": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500039": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500040": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500041": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500042": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500044": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500045": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500046": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500047": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500048": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500049": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500050": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500051": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500052": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500054": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500055": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500056": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500057": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500059": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500060": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500061": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500062": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500063": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500068": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500070": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500071": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500072": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500073": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500074": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500075": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500076": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500079": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500080": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500081": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500082": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500083": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500084": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500085": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500087": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500088": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500089": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500090": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500091": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500092": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500093": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500094": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500095": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500096": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500097": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500100": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500103": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "500104": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500107": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500108": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500109": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "500110": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "501106": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "501218": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "501401": {
    "city": "SECUNDERABAD",
    "yesBranchCode": "413"
  },
  "501403": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "501505": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "502032": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "502325": {
    "city": "HYDERABAD",
    "yesBranchCode": "424"
  },
  "506001": {
    "city": "WARANGAL",
    "yesBranchCode": "187"
  },
  "506002": {
    "city": "WARANGAL",
    "yesBranchCode": "187"
  },
  "506003": {
    "city": "PRAKASAM",
    "yesBranchCode": "187"
  },
  "506004": {
    "city": "WARANGAL",
    "yesBranchCode": "187"
  },
  "506005": {
    "city": "WARANGAL",
    "yesBranchCode": "187"
  },
  "506007": {
    "city": "WARANGAL",
    "yesBranchCode": "187"
  },
  "506009": {
    "city": "WARANGAL",
    "yesBranchCode": "187"
  },
  "506011": {
    "city": "WARANGAL",
    "yesBranchCode": "187"
  },
  "506013": {
    "city": "WARANGAL",
    "yesBranchCode": "187"
  },
  "506015": {
    "city": "WARANGAL",
    "yesBranchCode": "187"
  },
  "506330": {
    "city": "WARANGAL",
    "yesBranchCode": "187"
  },
  "508213": {
    "city": "SURYAPET",
    "yesBranchCode": "295"
  },
  "517001": {
    "city": "CHITTOOR",
    "yesBranchCode": "485"
  },
  "517002": {
    "city": "CHITTOOR",
    "yesBranchCode": "485"
  },
  "517101": {
    "city": "CHITTOOR",
    "yesBranchCode": "485"
  },
  "517127": {
    "city": "CHITTOOR",
    "yesBranchCode": "485"
  },
  "517501": {
    "city": "CHITTOOR",
    "yesBranchCode": "485"
  },
  "517503": {
    "city": "CHITTOOR",
    "yesBranchCode": "485"
  },
  "517505": {
    "city": "CHITTOOR",
    "yesBranchCode": "485"
  },
  "517506": {
    "city": "CHITTOOR",
    "yesBranchCode": "485"
  },
  "517507": {
    "city": "CHITTOOR",
    "yesBranchCode": "485"
  },
  "517520": {
    "city": "CHITTOOR",
    "yesBranchCode": "485"
  },
  "520001": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "520002": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "520003": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "520004": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "520007": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "520008": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "520010": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "520011": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "520013": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "520015": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "521001": {
    "city": "MACHILIPATNAM",
    "yesBranchCode": "1000"
  },
  "521002": {
    "city": "MACHILIPATNAM",
    "yesBranchCode": "1000"
  },
  "521101": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "521102": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "521104": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "521107": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "521108": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "521131": {
    "city": "MACHILIPATNAM",
    "yesBranchCode": "1000"
  },
  "521137": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "521138": {
    "city": "MACHILIPATNAM",
    "yesBranchCode": "1000"
  },
  "521139": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "521151": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "521162": {
    "city": "MACHILIPATNAM",
    "yesBranchCode": "1000"
  },
  "521225": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "521228": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "521241": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "521366": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "521456": {
    "city": "VIJAYAWADA",
    "yesBranchCode": "948"
  },
  "522001": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522002": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522003": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522004": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522005": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522006": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522007": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522017": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522034": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522234": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522415": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522503": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522509": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522601": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522603": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "522661": {
    "city": "GUNTUR",
    "yesBranchCode": "587"
  },
  "524005": {
    "city": "NELLORE",
    "yesBranchCode": "631"
  },
  "524137": {
    "city": "NELLORE",
    "yesBranchCode": "631"
  },
  "524305": {
    "city": "NELLORE",
    "yesBranchCode": "631"
  },
  "524314": {
    "city": "NELLORE",
    "yesBranchCode": "631"
  },
  "524320": {
    "city": "NELLORE",
    "yesBranchCode": "631"
  },
  "524344": {
    "city": "NELLORE",
    "yesBranchCode": "631"
  },
  "524345": {
    "city": "NELLORE",
    "yesBranchCode": "631"
  },
  "524346": {
    "city": "NELLORE",
    "yesBranchCode": "631"
  },
  "530001": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530002": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530003": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530004": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530005": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530007": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530008": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530009": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530011": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530012": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530013": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530014": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530015": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530016": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530017": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530018": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530020": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530022": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530024": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530026": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530027": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530028": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530029": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530031": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530032": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530035": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530040": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530043": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530044": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530045": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530046": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "530051": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "531021": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "531163": {
    "city": "VISAKHAPATNAM",
    "yesBranchCode": "46"
  },
  "533001": {
    "city": "KAKINADA",
    "yesBranchCode": "1046"
  },
  "533002": {
    "city": "KAKINADA",
    "yesBranchCode": "1046"
  },
  "533003": {
    "city": "KAKINADA",
    "yesBranchCode": "1046"
  },
  "533004": {
    "city": "KAKINADA",
    "yesBranchCode": "1046"
  },
  "533005": {
    "city": "KAKINADA",
    "yesBranchCode": "1046"
  },
  "533103": {
    "city": "RAJAHMUNDRY",
    "yesBranchCode": "615"
  },
  "533104": {
    "city": "RAJAHMUNDRY",
    "yesBranchCode": "615"
  },
  "533105": {
    "city": "RAJAHMUNDRY",
    "yesBranchCode": "615"
  },
  "533106": {
    "city": "RAJAHMUNDRY",
    "yesBranchCode": "615"
  },
  "533461": {
    "city": "KAKINADA",
    "yesBranchCode": "1046"
  },
  "533462": {
    "city": "KAKINADA",
    "yesBranchCode": "1046"
  },
  "533463": {
    "city": "KAKINADA",
    "yesBranchCode": "1046"
  },
  "535001": {
    "city": "VIZIANAGARAM",
    "yesBranchCode": "710"
  },
  "535002": {
    "city": "VIZIANAGARAM",
    "yesBranchCode": "710"
  },
  "535217": {
    "city": "VIZIANAGARAM",
    "yesBranchCode": "710"
  },
  "560001": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560002": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560003": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560004": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560005": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560006": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560007": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560008": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560009": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560010": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560011": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560012": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560013": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560014": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560015": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560016": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560017": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560018": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560019": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560020": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560021": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560022": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560023": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560024": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560025": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560026": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560027": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560028": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560029": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560030": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560032": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560033": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560034": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560036": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560037": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560038": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560039": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560040": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560041": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560042": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560043": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560045": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560046": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560047": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560048": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560049": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560050": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560051": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560052": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560053": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560054": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560055": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560056": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560057": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560058": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560059": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560060": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560061": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560062": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560063": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560064": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560065": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560066": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560067": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560068": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560069": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560070": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560071": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560072": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560073": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560075": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560076": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560077": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560078": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560080": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560081": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560082": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560083": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560084": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560085": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560086": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560087": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560088": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560089": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560090": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560091": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560092": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560093": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560094": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560095": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560096": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560097": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560098": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560099": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560100": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560102": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560103": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560104": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560107": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560109": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560110": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560111": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560112": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560113": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560114": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560115": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560116": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560117": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560300": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "560500": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "561204": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "561205": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "562110": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "562111": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "562122": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "562123": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "562125": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "562129": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "562130": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "562132": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "562135": {
    "city": "BENGALURU",
    "yesBranchCode": "639"
  },
  "570001": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570002": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570003": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570004": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570005": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570006": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570007": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570008": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570009": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570010": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570011": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570012": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570014": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570015": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570016": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570017": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570018": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570019": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570020": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570023": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570025": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570027": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570030": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570031": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570032": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570033": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "570034": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "571101": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "571104": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "571105": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "571108": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "571116": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "571118": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "571119": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "571120": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "571122": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "571124": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "571125": {
    "city": "MYSORE",
    "yesBranchCode": "452"
  },
  "571201": {
    "city": "KODAGU",
    "yesBranchCode": "452"
  },
  "571401": {
    "city": "MANDYA",
    "yesBranchCode": "452"
  },
  "573201": {
    "city": "HASSAN",
    "yesBranchCode": "1026"
  },
  "574101": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574102": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574103": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574104": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574105": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574106": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574107": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574108": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574109": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574110": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574111": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574112": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574113": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574114": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574115": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574116": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574117": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574118": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574119": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574122": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574129": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574141": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574142": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574143": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574144": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574145": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574146": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574148": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574150": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574151": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574197": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574198": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574199": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574202": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574203": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574210": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574211": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574212": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574213": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574216": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574217": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574218": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574220": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574221": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574222": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574223": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574224": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574225": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574226": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574227": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574228": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574229": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574230": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574231": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574232": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574233": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574234": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574235": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574236": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574237": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574238": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574239": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574240": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574241": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574242": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574243": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574244": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "574248": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574259": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574260": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574265": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574267": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574274": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574279": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574285": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574313": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574314": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574323": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574324": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "574325": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575001": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575002": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575003": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575004": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575005": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575006": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575007": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575008": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575010": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575011": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575013": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575014": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575015": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575016": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575017": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575018": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575019": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575020": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575022": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575023": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575025": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575028": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575029": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "575030": {
    "city": "MANGALORE",
    "yesBranchCode": "63"
  },
  "576101": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576102": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576103": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576105": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576106": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576107": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576108": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576111": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576112": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576113": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576114": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576115": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576117": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576120": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576121": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576122": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576124": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576210": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576215": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576223": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576225": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "576234": {
    "city": "UDUPI",
    "yesBranchCode": "609"
  },
  "580001": {
    "city": "DHARWAD",
    "yesBranchCode": "1008"
  },
  "580002": {
    "city": "DHARWAD",
    "yesBranchCode": "1008"
  },
  "580003": {
    "city": "DHARWAD",
    "yesBranchCode": "1008"
  },
  "580004": {
    "city": "DHARWAD",
    "yesBranchCode": "1008"
  },
  "580006": {
    "city": "DHARWAD",
    "yesBranchCode": "1008"
  },
  "580008": {
    "city": "DHARWAD",
    "yesBranchCode": "1008"
  },
  "580020": {
    "city": "DHARWAD",
    "yesBranchCode": "249"
  },
  "580021": {
    "city": "DHARWAD",
    "yesBranchCode": "249"
  },
  "580023": {
    "city": "DHARWAD",
    "yesBranchCode": "249"
  },
  "580024": {
    "city": "DHARWAD",
    "yesBranchCode": "249"
  },
  "580025": {
    "city": "DHARWAD",
    "yesBranchCode": "249"
  },
  "580027": {
    "city": "DHARWAD",
    "yesBranchCode": "249"
  },
  "580028": {
    "city": "HUBLI",
    "yesBranchCode": "249"
  },
  "580029": {
    "city": "DHARWAD",
    "yesBranchCode": "249"
  },
  "580030": {
    "city": "DHARWAD",
    "yesBranchCode": "249"
  },
  "590001": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "590003": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "590005": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "590006": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "590008": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "590009": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "590010": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "590011": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "590015": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "590016": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "590017": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "590018": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "590019": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "590020": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591101": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591102": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591103": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591104": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591106": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591107": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591108": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591109": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591110": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591112": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591113": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591114": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591115": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591116": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591117": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591118": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591120": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591121": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591122": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591123": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "591124": {
    "city": "BELGAUM",
    "yesBranchCode": "547"
  },
  "600001": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600002": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600003": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600004": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600005": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600006": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600007": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600008": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600009": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600010": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600011": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600012": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600013": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600014": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600015": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600016": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600017": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600018": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600019": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600020": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600021": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600022": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600023": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600024": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600025": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600026": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600027": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600028": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600029": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600030": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600031": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600032": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600033": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600034": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600035": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600036": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600037": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600038": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600039": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600040": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600041": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600042": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600043": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600044": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600045": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600046": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600047": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600048": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600049": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600050": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600051": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600052": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600053": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600054": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600055": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600056": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600057": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600058": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600059": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600060": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600061": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600062": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600063": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600064": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600065": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600066": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600067": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600068": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600069": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600070": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600071": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600072": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600073": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600074": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600075": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600076": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600077": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600078": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600079": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600080": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600081": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600082": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600083": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600084": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600085": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600086": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600087": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600088": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600089": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600090": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600091": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600092": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600093": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600094": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600095": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600096": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600097": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600098": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600099": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600100": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600101": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600102": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600103": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600104": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600105": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600106": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600107": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600108": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600109": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600110": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600111": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600112": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600113": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600114": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600115": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600116": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600117": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600118": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600119": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600120": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600122": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600123": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600124": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600125": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600126": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600127": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600128": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600129": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "600130": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "601201": {
    "city": "TIRUVALLUR",
    "yesBranchCode": "927"
  },
  "601203": {
    "city": "TIRUVALLUR",
    "yesBranchCode": "927"
  },
  "601204": {
    "city": "TIRUVALLUR",
    "yesBranchCode": "927"
  },
  "601301": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "601302": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "602000": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "602001": {
    "city": "TIRUVALLUR",
    "yesBranchCode": "927"
  },
  "602002": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "602003": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "602024": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "602025": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "602026": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "602028": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "602101": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "602102": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "602103": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "602105": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "602106": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "602107": {
    "city": "CHENNAI",
    "yesBranchCode": "103"
  },
  "602108": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "602117": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "603001": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603002": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603003": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603004": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603101": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603103": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "603106": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603107": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603108": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603109": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603110": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603111": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603112": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "603201": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603202": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603203": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603204": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603209": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603210": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603211": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603301": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "603303": {
    "city": "CHENGALPATTU",
    "yesBranchCode": "95"
  },
  "605001": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605002": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605003": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605004": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605005": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605006": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605007": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605008": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605009": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605010": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605011": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605013": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605014": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605101": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605102": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605106": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "605110": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "607402": {
    "city": "PONDICHERRY",
    "yesBranchCode": "542"
  },
  "609601": {
    "city": "NAGAPATTINAM",
    "yesBranchCode": "523"
  },
  "609602": {
    "city": "KARAIKAL",
    "yesBranchCode": "542"
  },
  "609603": {
    "city": "NAGAPATTINAM",
    "yesBranchCode": "523"
  },
  "609604": {
    "city": "NAGAPATTINAM",
    "yesBranchCode": "523"
  },
  "609605": {
    "city": "NAGAPATTINAM",
    "yesBranchCode": "523"
  },
  "609606": {
    "city": "NAGAPATTINAM",
    "yesBranchCode": "523"
  },
  "609607": {
    "city": "NAGAPATTINAM",
    "yesBranchCode": "523"
  },
  "609609": {
    "city": "NAGAPATTINAM",
    "yesBranchCode": "523"
  },
  "620001": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620002": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620003": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620004": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620005": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620006": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620007": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620008": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620009": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620010": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620011": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620012": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620013": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620014": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620015": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620016": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620017": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620018": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620019": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620020": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620021": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620022": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620023": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620024": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620025": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620026": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620101": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "620102": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "621005": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "621105": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "621112": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "621213": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "621216": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "621218": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "621601": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "621703": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "625001": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625002": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625003": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625004": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625005": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625006": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625007": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625008": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625009": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625010": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625011": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625012": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625014": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625015": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625016": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625017": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625018": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625019": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625020": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625021": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625022": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625104": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625106": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625107": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625122": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625201": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625214": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625218": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625301": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625401": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625402": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625501": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625503": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625514": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625532": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625537": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625702": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "625706": {
    "city": "MADURAI",
    "yesBranchCode": "616"
  },
  "627851": {
    "city": "TIRUNELVELI",
    "yesBranchCode": "942"
  },
  "631203": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "631402": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "631501": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "631502": {
    "city": "KANCHIPURAM",
    "yesBranchCode": "1064"
  },
  "632001": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632002": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632004": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632006": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632007": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632008": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632009": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632010": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632011": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632012": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632013": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632014": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632104": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632114": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632401": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632403": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632406": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "632513": {
    "city": "VELLORE",
    "yesBranchCode": "293"
  },
  "635101": {
    "city": "KRISHNAGIRI",
    "yesBranchCode": "123"
  },
  "635102": {
    "city": "KRISHNAGIRI",
    "yesBranchCode": "123"
  },
  "635103": {
    "city": "KRISHNAGIRI",
    "yesBranchCode": "123"
  },
  "635109": {
    "city": "KRISHNAGIRI",
    "yesBranchCode": "123"
  },
  "635110": {
    "city": "KRISHNAGIRI",
    "yesBranchCode": "123"
  },
  "635802": {
    "city": "AMBUR",
    "yesBranchCode": "293"
  },
  "635807": {
    "city": "AMBUR",
    "yesBranchCode": "293"
  },
  "635811": {
    "city": "AMBUR",
    "yesBranchCode": "293"
  },
  "635814": {
    "city": "AMBUR",
    "yesBranchCode": "293"
  },
  "636001": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636002": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636003": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636004": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636005": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636006": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636007": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636008": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636009": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636010": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636011": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636012": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636013": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636014": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636015": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636016": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636030": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636103": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636104": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636106": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636115": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636122": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636140": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636201": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636202": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636203": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636204": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636302": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636304": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636306": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636307": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636308": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636309": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636451": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636455": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "636502": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "637501": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "637502": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "637504": {
    "city": "SALEM",
    "yesBranchCode": "79"
  },
  "638001": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638002": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638003": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638004": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638005": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638006": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638007": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638008": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638009": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638010": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638011": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638052": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638053": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638103": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638104": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638115": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638116": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638151": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638183": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638301": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638316": {
    "city": "ERODE",
    "yesBranchCode": "623"
  },
  "638812": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "639001": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639002": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639003": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639004": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639005": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639006": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639007": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639101": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "639102": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639103": {
    "city": "TIRUCHIRAPPALLI",
    "yesBranchCode": "523"
  },
  "639104": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639108": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639111": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639113": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639114": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639116": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639117": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639118": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639119": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639136": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639201": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639202": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "639205": {
    "city": "KARUR",
    "yesBranchCode": "744"
  },
  "641001": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641002": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641003": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641004": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641005": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641006": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641007": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641008": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641009": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641010": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641011": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641012": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641013": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641014": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641015": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641016": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641017": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641018": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641019": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641020": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641021": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641022": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641023": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641024": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641025": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641026": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641027": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641028": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641029": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641030": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641031": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641032": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641033": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641034": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641035": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641036": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641037": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641038": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641039": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641040": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641041": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641042": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641043": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641044": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641045": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641046": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641047": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641048": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641049": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641050": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641062": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641101": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641103": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641104": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641105": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641107": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641109": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641110": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641111": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641112": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641201": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641202": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "641302": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641401": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641402": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641406": {
    "city": "COIMBATORE",
    "yesBranchCode": "692"
  },
  "641407": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641601": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641602": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641603": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641604": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641605": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641606": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641607": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641608": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641652": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641653": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641654": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641655": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641659": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641662": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641663": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641664": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641665": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641666": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641670": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "641687": {
    "city": "TIRUPUR",
    "yesBranchCode": "484"
  },
  "642001": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642002": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642003": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642004": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642005": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642006": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642007": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642109": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642110": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642114": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642120": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642123": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642129": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642134": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642202": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "642205": {
    "city": "POLLACHI",
    "yesBranchCode": "285"
  },
  "670314": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673001": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673002": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673003": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673004": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673005": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673006": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673007": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673008": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673009": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673010": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673011": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673012": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673013": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673014": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673015": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673016": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673017": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673018": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673019": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673020": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673021": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673024": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673025": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673026": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673027": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673028": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673029": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673031": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673032": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673100": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673101": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673102": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673103": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673104": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673105": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673106": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673107": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673108": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673301": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673302": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673303": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673304": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673305": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673306": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673307": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673308": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673310": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673314": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673315": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673316": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673317": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673318": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673328": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673501": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673502": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673504": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673506": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673507": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673522": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673523": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673525": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673530": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673542": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673571": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673572": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673574": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673585": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673601": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673602": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673611": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673613": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673614": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673616": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673617": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673619": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673620": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673631": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673632": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673633": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673634": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673635": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673636": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673637": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673638": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673639": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673643": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673647": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673653": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673655": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "673661": {
    "city": "KOZHIKODE",
    "yesBranchCode": "492"
  },
  "678001": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678002": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678003": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678004": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678005": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678006": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678007": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678008": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678009": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678010": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678011": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678012": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678013": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678014": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678101": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678102": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678103": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678104": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678501": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678502": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678503": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678504": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678505": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678506": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678512": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678531": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678532": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678533": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678534": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678541": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678542": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678545": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678546": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678551": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678552": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678553": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678555": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678556": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678557": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678571": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678572": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678573": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678574": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678591": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678592": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678594": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678595": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678596": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678597": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678611": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678612": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678613": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678621": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678622": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678623": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678624": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678631": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678632": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678633": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678641": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678642": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678651": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678671": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678681": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678683": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678684": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678688": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678701": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678702": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678703": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678721": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678722": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678731": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "678732": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "679301": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "679302": {
    "city": "PALAKKAD",
    "yesBranchCode": "690"
  },
  "679531": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "679532": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680001": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680002": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680003": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680004": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680005": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680006": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680007": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680008": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680009": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680010": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680011": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680012": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680013": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680014": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680016": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680017": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680018": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680020": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680021": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680022": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680026": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680027": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680028": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680103": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680104": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680301": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680303": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680305": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680306": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680307": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680312": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680313": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680317": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680319": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680320": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680322": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680501": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680502": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680503": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680504": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680505": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680506": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680507": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680509": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680510": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680511": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680519": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680521": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680541": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680542": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680543": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680545": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680551": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680552": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680553": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680555": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680561": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680562": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680563": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680565": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680569": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680573": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680575": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680581": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680582": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680593": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680611": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680614": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680617": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680618": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680620": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680621": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680641": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680651": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680653": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680654": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680655": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680656": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680657": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680661": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680662": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680664": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680667": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680668": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680671": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680672": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680683": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680685": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680687": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680695": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680702": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680703": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680711": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680712": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680731": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680732": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680751": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "680771": {
    "city": "THRISSUR",
    "yesBranchCode": "516"
  },
  "682001": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682002": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682003": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682004": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682005": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682006": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682007": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682008": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682009": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682010": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682011": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682012": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682013": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682014": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682015": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682016": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682017": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682018": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682019": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682020": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682021": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682022": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682023": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682024": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682025": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682026": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682027": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682028": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682029": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682030": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682031": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682032": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682033": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682034": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682035": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682036": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682037": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682038": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682039": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682040": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682041": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682042": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682301": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682302": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682303": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682304": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682305": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682306": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682307": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682308": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682309": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682310": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682312": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682314": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682315": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682316": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682317": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682320": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682501": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682502": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682503": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682504": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682505": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682506": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682507": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682508": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682509": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682510": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "682511": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683101": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683102": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683103": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683104": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683105": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683106": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683107": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683108": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683109": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683110": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683111": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683112": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683317": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683501": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683502": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683503": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683504": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683511": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683512": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683513": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683514": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683515": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683516": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683517": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683518": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683519": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683522": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683547": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683556": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683561": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683562": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683563": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683564": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683565": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683571": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683572": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683573": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683578": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683579": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683580": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "683585": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "685586": {
    "city": "IDUKKI",
    "yesBranchCode": "454"
  },
  "685620": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686001": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686002": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686003": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686004": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686005": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686006": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686007": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686008": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686009": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686010": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686011": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686012": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686013": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686014": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686015": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686016": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686017": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686018": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686019": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686020": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686021": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686023": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686024": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686025": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686026": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686027": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686028": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686029": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686030": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686031": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686032": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686033": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686035": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686036": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686038": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686039": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686041": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686101": {
    "city": "CHANGANASSERY",
    "yesBranchCode": "941"
  },
  "686102": {
    "city": "CHANGANASSERY",
    "yesBranchCode": "941"
  },
  "686103": {
    "city": "CHANGANASSERY",
    "yesBranchCode": "941"
  },
  "686104": {
    "city": "CHANGANASSERY",
    "yesBranchCode": "941"
  },
  "686105": {
    "city": "CHANGANASSERY",
    "yesBranchCode": "941"
  },
  "686106": {
    "city": "CHANGANASSERY",
    "yesBranchCode": "941"
  },
  "686107": {
    "city": "CHANGANASSERY",
    "yesBranchCode": "941"
  },
  "686108": {
    "city": "CHANGANASSERY",
    "yesBranchCode": "941"
  },
  "686141": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686501": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686512": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "686516": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686535": {
    "city": "CHANGANASSERY",
    "yesBranchCode": "941"
  },
  "686539": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686544": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686546": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686549": {
    "city": "CHANGANASSERY",
    "yesBranchCode": "941"
  },
  "686560": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686561": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686562": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686563": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686566": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686605": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686608": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686611": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686631": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686632": {
    "city": "KOTTAYAM",
    "yesBranchCode": "484"
  },
  "686640": {
    "city": "CHANGANASSERY",
    "yesBranchCode": "941"
  },
  "688534": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "688541": {
    "city": "KOCHI",
    "yesBranchCode": "759"
  },
  "689101": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689102": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689104": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689105": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689106": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689107": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689108": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689110": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689111": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689112": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689113": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689115": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689503": {
    "city": "CHANGANASSERY",
    "yesBranchCode": "941"
  },
  "689506": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689531": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689532": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689541": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689542": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689546": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689547": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689548": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689549": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689550": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689551": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689573": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689581": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689582": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689591": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689594": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "689626": {
    "city": "TIRUVALLA",
    "yesBranchCode": "528"
  },
  "690518": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "690519": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "690520": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "690521": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "690522": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "690524": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "690528": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "690536": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "690538": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "690539": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "690540": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "690544": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "690573": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691001": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691002": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691003": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691004": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691005": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691006": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691007": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691008": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691009": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691010": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691011": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691012": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691013": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691014": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691015": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691016": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691019": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691020": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691021": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691301": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691302": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691303": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691304": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691319": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691334": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691500": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691501": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691502": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691503": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691504": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691505": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691506": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691507": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691509": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691510": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691511": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691512": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691515": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691516": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691520": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691531": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691536": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691537": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691540": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691543": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691557": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691560": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691566": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691571": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691572": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691573": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691574": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691576": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691577": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691578": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691579": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691581": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691582": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691583": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691584": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691585": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691589": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691590": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691601": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "691602": {
    "city": "KOLLAM",
    "yesBranchCode": "463"
  },
  "695001": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695002": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695003": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695004": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695005": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695006": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695007": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695008": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695009": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695010": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695011": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695012": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695013": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695014": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695015": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695016": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695017": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695018": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695019": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695020": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695021": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695022": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695023": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695024": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695025": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695026": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695027": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695028": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695029": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695030": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695031": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695032": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695033": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695034": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695035": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695036": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695037": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695038": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695039": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695040": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695041": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695042": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695043": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695044": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695099": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695101": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695102": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695103": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695104": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695121": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695122": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695123": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695125": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695126": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695132": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695133": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695141": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695142": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695144": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695301": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695302": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695304": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695306": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695313": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695316": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695317": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695501": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695502": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695503": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695504": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695505": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695506": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695507": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695512": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695521": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695522": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695523": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695524": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695525": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695526": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695527": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695541": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695564": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695568": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695571": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695572": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695573": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695575": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695581": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695582": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695583": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695584": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695586": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695587": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695588": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695601": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695607": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "695615": {
    "city": "THIRUVANANTHAPURAM",
    "yesBranchCode": "454"
  },
  "700001": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700002": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700003": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700004": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700005": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700006": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700007": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700008": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700009": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700010": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700011": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700012": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700013": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700014": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700015": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700016": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700017": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700018": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700019": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700020": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700021": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700022": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700023": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700024": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700025": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700026": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700027": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700028": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700029": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700030": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700031": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700032": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700033": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700034": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700035": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700036": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700037": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700038": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700039": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700040": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700041": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700042": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700043": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700044": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700045": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700046": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700047": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700048": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700049": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700050": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700051": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700052": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700053": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700054": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700055": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700056": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700057": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700058": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700059": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700060": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700061": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700062": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700063": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700064": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700065": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700066": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700067": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700068": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700069": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700070": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700071": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700072": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700073": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700074": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700075": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700076": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700077": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700078": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700079": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700080": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700081": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700082": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700083": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700084": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700085": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700086": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700087": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700088": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700089": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700090": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700091": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700092": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700093": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700094": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700095": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700096": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700097": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700098": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700099": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700100": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700101": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700102": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700103": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700104": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700105": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700106": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700107": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700108": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700109": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700110": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700111": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700112": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700113": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700114": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700115": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700116": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700117": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700118": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700119": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700120": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700121": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700122": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700123": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700124": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700125": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700126": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700127": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700128": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700129": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700130": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700131": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700132": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700133": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700134": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700135": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700136": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700137": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700138": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700139": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700140": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700141": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700142": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700143": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700144": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700145": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700146": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700147": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700148": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700149": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700150": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700151": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700152": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700153": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700154": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700155": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700156": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700157": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700158": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700159": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700160": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "700191": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700192": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700503": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700516": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "700912": {
    "city": "KOLKATA",
    "yesBranchCode": "786"
  },
  "711101": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711102": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711103": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711104": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711105": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711106": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711107": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711108": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711109": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711110": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711111": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711112": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711113": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711114": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711115": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711125": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711139": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711201": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711202": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711203": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711204": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711205": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711206": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711213": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711224": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711225": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711226": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711227": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711301": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711302": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711303": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711304": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711305": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711306": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711307": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711308": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711309": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711310": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711312": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711313": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711314": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711315": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711316": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711317": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711322": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711401": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711402": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711403": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711404": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711405": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711406": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711408": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711410": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711411": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711412": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711413": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "711414": {
    "city": "HOWRAH",
    "yesBranchCode": "128"
  },
  "712072": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712101": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712102": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712103": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712104": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712105": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712121": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712122": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712123": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712124": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712125": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712126": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712129": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712131": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712134": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712135": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712136": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712137": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712138": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712139": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712146": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712147": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712148": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712149": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712150": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712151": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712152": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712201": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712202": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712203": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712204": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712205": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712221": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712222": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712223": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712232": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712233": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712234": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712235": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712237": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712243": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712245": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712246": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712247": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712248": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712249": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712250": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712254": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712258": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712301": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712302": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712303": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712304": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712305": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712308": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712310": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712311": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712331": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712335": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712401": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712402": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712403": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712404": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712405": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712406": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712407": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712408": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712409": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712410": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712412": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712413": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712414": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712415": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712416": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712417": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "712617": {
    "city": "HOOGHLY",
    "yesBranchCode": "342"
  },
  "713101": {
    "city": "BURDWAN",
    "yesBranchCode": "261"
  },
  "713102": {
    "city": "BURDWAN",
    "yesBranchCode": "261"
  },
  "713103": {
    "city": "BARDHAMAN",
    "yesBranchCode": "261"
  },
  "713104": {
    "city": "BURDWAN",
    "yesBranchCode": "261"
  },
  "713124": {
    "city": "BURDWAN",
    "yesBranchCode": "261"
  },
  "713128": {
    "city": "BURDWAN",
    "yesBranchCode": "261"
  },
  "713142": {
    "city": "BURDWAN",
    "yesBranchCode": "261"
  },
  "713148": {
    "city": "BARDHAMAN",
    "yesBranchCode": "76"
  },
  "713149": {
    "city": "BURDWAN",
    "yesBranchCode": "261"
  },
  "713201": {
    "city": "BARDHAMAN",
    "yesBranchCode": "76"
  },
  "713202": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713203": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713204": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713205": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713206": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713207": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713208": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713209": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713210": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713211": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713212": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713213": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713214": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713215": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713216": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713217": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713301": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713302": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713303": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713304": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713305": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713315": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713323": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713324": {
    "city": "BARDHAMAN",
    "yesBranchCode": "179"
  },
  "713325": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713326": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713330": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713331": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713332": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713333": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713334": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713335": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713336": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713337": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713338": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713339": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713340": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713341": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713342": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713343": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713344": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713346": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713347": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713357": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713358": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713359": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713360": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713361": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713362": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713363": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713365": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713369": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713370": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713371": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713372": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713373": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713376": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713378": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713381": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713384": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713385": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713386": {
    "city": "ASANSOL",
    "yesBranchCode": "76"
  },
  "713424": {
    "city": "BURDWAN",
    "yesBranchCode": "261"
  },
  "721101": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721102": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721121": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721122": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721124": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721125": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721126": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721127": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721128": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721129": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721130": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721131": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721132": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721133": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721134": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721135": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721136": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721137": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721139": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721140": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721143": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721144": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721145": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721146": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721147": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721148": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721149": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721150": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721151": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721152": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721153": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721154": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721155": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721156": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721157": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721158": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721159": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721160": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721161": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721166": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721171": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721172": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721201": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721211": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721212": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721222": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721232": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721242": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721260": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721301": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721302": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721303": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721304": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721305": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721306": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721401": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721402": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721403": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721404": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721405": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721406": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721420": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721422": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721423": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721424": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721425": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721426": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721427": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721428": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721429": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721430": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721431": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721432": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721433": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721434": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721435": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721436": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721437": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721438": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721439": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721440": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721441": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721442": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721443": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721444": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721445": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721446": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721447": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721448": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721449": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721450": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721451": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721452": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721453": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721454": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721455": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721456": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721457": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721458": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721463": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721467": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721501": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721503": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721504": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721505": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721506": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721507": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721513": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721514": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721515": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721516": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721517": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721601": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721602": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721603": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721604": {
    "city": "HALDIA",
    "yesBranchCode": "128"
  },
  "721606": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721607": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721624": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721625": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721626": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721627": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721628": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721629": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721631": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721632": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721633": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721634": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721635": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721636": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721637": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721641": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721642": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721643": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721644": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721645": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721646": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721647": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721648": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721649": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721650": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721651": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721652": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721653": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721654": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721655": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721656": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721657": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721658": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "721659": {
    "city": "MIDNAPORE",
    "yesBranchCode": "491"
  },
  "734001": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734002": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734003": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734004": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734005": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734006": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734007": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734008": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734009": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734010": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734011": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734012": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734013": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734014": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "734015": {
    "city": "DARJILING",
    "yesBranchCode": "552"
  },
  "741235": {
    "city": "NADIA",
    "yesBranchCode": "342"
  },
  "741236": {
    "city": "NADIA",
    "yesBranchCode": "342"
  },
  "743012": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743101": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743102": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743120": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743121": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743122": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743123": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743124": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743125": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743126": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743127": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743128": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743129": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743130": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743132": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743133": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743134": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743135": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743136": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743144": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743145": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743165": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743166": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743170": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743175": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743176": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743177": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743178": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743179": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743180": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743186": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743187": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743188": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743193": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743194": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743201": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743202": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743203": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743204": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743210": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743221": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743222": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743223": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743232": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743233": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743234": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743235": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743245": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743247": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743248": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743249": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743251": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743252": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743262": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743263": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743268": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743270": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743271": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743272": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743286": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743287": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743290": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743291": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743292": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743293": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743294": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743297": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743312": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743318": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743329": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743330": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743331": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743332": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743336": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743337": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743338": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743345": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743347": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743348": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743349": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743351": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743354": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743355": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743356": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743357": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743363": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743368": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743370": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743371": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743372": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743374": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743375": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743376": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743377": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743378": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743382": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743383": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743384": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743387": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743395": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743398": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743399": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743401": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743405": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743411": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743412": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743422": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743423": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743424": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743425": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743426": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743427": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743428": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743429": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743435": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743437": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743438": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743439": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743442": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743445": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743446": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743456": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743502": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743503": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743504": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743513": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743609": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743610": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743611": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743613": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743701": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743702": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743704": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743710": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "743711": {
    "city": "NORTH 24 PARGANAS",
    "yesBranchCode": "760"
  },
  "751001": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751002": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751003": {
    "city": "KHORDA",
    "yesBranchCode": "1066"
  },
  "751004": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751005": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751006": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751007": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751008": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751009": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751010": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751011": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751012": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751013": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751014": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751015": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751016": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751017": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751018": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751019": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751020": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751021": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751022": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751023": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751024": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751025": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751026": {
    "city": "KHORDA",
    "yesBranchCode": "1066"
  },
  "751027": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751028": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751029": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751030": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "751031": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752001": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752002": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752003": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752010": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752011": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752012": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752013": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752014": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752015": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752016": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752017": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752018": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752019": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752020": {
    "city": "KHORDA",
    "yesBranchCode": "1066"
  },
  "752021": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752022": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752023": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752024": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752025": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752026": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752027": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752030": {
    "city": "KHORDA",
    "yesBranchCode": "1066"
  },
  "752031": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752032": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752034": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752035": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752037": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752038": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752045": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752046": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752055": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752057": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752060": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752061": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752062": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752063": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752064": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752065": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752066": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752068": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752077": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752078": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752079": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752080": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752081": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752082": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752083": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752084": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752085": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752089": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752090": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752091": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752092": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752094": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752100": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752103": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752105": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752106": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752107": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752108": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752109": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752110": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752111": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752113": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752114": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752115": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "752116": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752118": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752119": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "752120": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "752121": {
    "city": "PURI",
    "yesBranchCode": "252"
  },
  "753001": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "753002": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "753003": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "753004": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "753006": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "753007": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "753008": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "753009": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "753010": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "753011": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "753012": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "753013": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "753014": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "753015": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754001": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754002": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754003": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754004": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754005": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754006": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754007": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754008": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754009": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754010": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754011": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754012": {
    "city": "BHUBANESWAR",
    "yesBranchCode": "93"
  },
  "754013": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754018": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754021": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754022": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754025": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754026": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754028": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754029": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754030": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754032": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754034": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754035": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754037": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754071": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754100": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754103": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754105": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754112": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754130": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754131": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754134": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754153": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754200": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754201": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754202": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754203": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754204": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754206": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754207": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754208": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754209": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754210": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754211": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754212": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754213": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754214": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754215": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754216": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754217": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754218": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754219": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754220": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754221": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754222": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754224": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754225": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754227": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754228": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754231": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754239": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754240": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754244": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754245": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754246": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754248": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754250": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754253": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754289": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754290": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754292": {
    "city": "KENDRAPARA",
    "yesBranchCode": "612"
  },
  "754293": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754295": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "754297": {
    "city": "CUTTACK",
    "yesBranchCode": "252"
  },
  "759001": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759013": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759014": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759015": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759016": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759017": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759018": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759019": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759020": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759021": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759022": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759023": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759024": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759025": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759026": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759027": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759028": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759029": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759037": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759039": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759040": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759100": {
    "city": "ANGUL",
    "yesBranchCode": "612"
  },
  "759101": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759102": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759104": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759105": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759106": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759107": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759111": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759116": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759117": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759118": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759119": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759120": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759121": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759122": {
    "city": "ANGUL",
    "yesBranchCode": "612"
  },
  "759123": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759124": {
    "city": "ANGUL",
    "yesBranchCode": "612"
  },
  "759125": {
    "city": "ANGUL",
    "yesBranchCode": "612"
  },
  "759126": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759127": {
    "city": "ANGUL",
    "yesBranchCode": "612"
  },
  "759128": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759129": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759130": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759132": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759141": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759143": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759145": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759146": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759147": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "759148": {
    "city": "DHENKANAL",
    "yesBranchCode": "612"
  },
  "768001": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768002": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768003": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768004": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768005": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768006": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768016": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768017": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768018": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768019": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768020": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768025": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768026": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768027": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768029": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768030": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768031": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768032": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768033": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768034": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768035": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768037": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768039": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768040": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768045": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768048": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768049": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768050": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768052": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768102": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768103": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768104": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768105": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768106": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768107": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768108": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768109": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768110": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768111": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768112": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768113": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768115": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768118": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768119": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768121": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768200": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768201": {
    "city": "JHARSUGUDA",
    "yesBranchCode": "1054"
  },
  "768202": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768203": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768204": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768210": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768211": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768212": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768213": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768214": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768215": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768216": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768217": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768218": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768219": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768220": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768221": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768222": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768224": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768225": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768226": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768227": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768228": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768233": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "768234": {
    "city": "SAMBALPUR",
    "yesBranchCode": "1054"
  },
  "769001": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769002": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769003": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769004": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769005": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769006": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769007": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769008": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769009": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769010": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769011": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769012": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769013": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769014": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769015": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769016": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769042": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "769043": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770001": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770002": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770011": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770012": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770013": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770014": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770015": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770017": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770018": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770019": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770020": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770021": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770022": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770023": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770024": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770025": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770031": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770032": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770033": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770034": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770035": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770036": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770037": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770038": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770039": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770040": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770041": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770042": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770043": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770044": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770046": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770048": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770051": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770052": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770070": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770072": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770073": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770074": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770075": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "770076": {
    "city": "SUNDERGARH",
    "yesBranchCode": "81"
  },
  "781001": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781002": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781003": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781004": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781005": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781006": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781007": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781008": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781009": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781010": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781011": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781012": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781013": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781014": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781015": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781016": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781017": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781018": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781019": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781020": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781021": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781022": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781023": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781024": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781025": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781026": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781027": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781028": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781029": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781030": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781031": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781032": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781034": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781035": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781036": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781037": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781038": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781039": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "781040": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "782402": {
    "city": "GUWAHATI",
    "yesBranchCode": "482"
  },
  "800001": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800002": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800003": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800004": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800005": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800006": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800007": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800008": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800009": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800010": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800011": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800012": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800013": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800014": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800015": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800016": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800017": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800018": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800019": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800020": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800021": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800022": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800023": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800024": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800025": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800026": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800027": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800028": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "800030": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801102": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801103": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801104": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801105": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801106": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801108": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801109": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801110": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801111": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801112": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801113": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801503": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801505": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801506": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "801507": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "803201": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "803202": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "803203": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "803211": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "804451": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "804452": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "804453": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "804454": {
    "city": "PATNA",
    "yesBranchCode": "620"
  },
  "818142": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "825102": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "826006": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "826007": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "826008": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "826009": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "826010": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "827302": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "828119": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828120": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828121": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828122": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828123": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828124": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828125": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828126": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828127": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828128": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828129": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828130": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828131": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828132": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828133": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828134": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828135": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828142": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828201": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828202": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828203": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828204": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828205": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828206": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828207": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828301": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828302": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828303": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828304": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828305": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828306": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828307": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828309": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828401": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828402": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "828403": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "828404": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829104": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829107": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829111": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829112": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829113": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829114": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829115": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "829116": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829121": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829123": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829127": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829128": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829129": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829132": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829144": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829149": {
    "city": "BOKARO",
    "yesBranchCode": "94"
  },
  "829301": {
    "city": "DHANBAD",
    "yesBranchCode": "385"
  },
  "831001": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831002": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831003": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831004": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831005": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831006": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831007": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831009": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831010": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831011": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831012": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831013": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831014": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831015": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831016": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831017": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831018": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831019": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831020": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "831021": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832101": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832102": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832103": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832104": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832105": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832106": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832107": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832108": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832109": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832110": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832111": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832112": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832113": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832301": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832302": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832303": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832304": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832401": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832402": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832403": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "832404": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833101": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833102": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833103": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833104": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833105": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833106": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833201": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833202": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833203": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833204": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833212": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833213": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833214": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833215": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833216": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833217": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833218": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833219": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833220": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833221": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833222": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "833223": {
    "city": "SINGHBHUM",
    "yesBranchCode": "180"
  },
  "834011": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "834012": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "834013": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835101": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835102": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835103": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835201": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835202": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835203": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835204": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835205": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835208": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835209": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835212": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835213": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835214": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835215": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835216": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835217": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835218": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835219": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835220": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835221": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835222": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835225": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835226": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835228": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835229": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835230": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835231": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835232": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835233": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835234": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835235": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835301": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835303": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  "835325": {
    "city": "RANCHI",
    "yesBranchCode": "80"
  },
  // "263145": {
  //    "city": "PANTNAGAR",
  //    "yesBranchCode": "1099"
  // },
  // "247664": {
  //    "city": "ROORKEE",
  //    "yesBranchCode": "731"
  // },
  // "173201": {
  //    "city": "BADDI",
  //    "yesBranchCode": "146"
  // },
  "302721": {
    "city": "KALOL",
    "yesBranchCode": "548"
  },
  "302725": {
    "city": "KALOL",
    "yesBranchCode": "548"
  },
  "456002": {
    "city": "UJJAIN",
    "yesBranchCode": "381"
  },
  "456004": {
    "city": "UJJAIN",
    "yesBranchCode": "381"
  },
  "456005": {
    "city": "UJJAIN",
    "yesBranchCode": "381"
  },
  "456007": {
    "city": "UJJAIN",
    "yesBranchCode": "381"
  },
  "456008": {
    "city": "UJJAIN",
    "yesBranchCode": "381"
  },
  "456009": {
    "city": "UJJAIN",
    "yesBranchCode": "381"
  },
  "370204": {
    "city": "GANDHIDHAM",
    "yesBranchCode": "548"
  },
  // "370210": {
  //    "city": "GANDHIDHAM",
  //    "yesBranchCode": "548"
  // },
  "370202": {
    "city": "GANDHIDHAM",
    "yesBranchCode": "548"
  }
};

export {
  yesBranchCodesList
}